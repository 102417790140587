import React, { useState, useEffect } from 'react';
import Thismonthrank from './Thismonth'


function TeamRank() {
    // State to store unique teams and total calls for each team
  
    return (
        <div>
            <Thismonthrank/>
           
        </div>
    );
}

export default TeamRank;