import React from 'react'
// Chakra Import

import {
  ThemeEditor as ThemeEditorContainer,
  ThemeEditorDrawer,
  ThemeEditorColors,
  ThemeEditorFontSizes,


} from '@hypertheme-editor/chakra-ui'

import { Button, Icon,Flex, Text, useColorModeValue,Image } from '@chakra-ui/react'
import { CgColorPicker } from 'react-icons/cg'
import { ImFontSize } from 'react-icons/im'
import { MdPalette } from 'react-icons/md'



export function ThemeEditor(props) {

  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const textColor = useColorModeValue('secondaryGray.900', 'white');



  return (
    <ThemeEditorContainer>
      <ThemeEditorButton {...props} />
      <ThemeEditorDrawer hideUpgradeToPro>
        <ThemeEditorColors icon={CgColorPicker} title="Colors" />
        <ThemeEditorFontSizes icon={ImFontSize} title="Font Sizes" />
      </ThemeEditorDrawer>
    </ThemeEditorContainer>
  )
}

function ThemeEditorButton({ onOpen, navbarIcon, ...rest }) {
  return (

      <Button
      width='100%'
      p='0px'
      colorScheme={{ base:'purple' , lg:'grey' }}
      _focus={{ boxShadow: 'none' }}
      onClick={onOpen}
      {...rest}
    >


     
      <Icon
      display={{ base: 'none', sm: 'none' , lg: 'yes', md: 'yes', '2xl': 'yes'}}

          h='25px'
          w='25px'
          color={navbarIcon}
          as={MdPalette}
        />
    </Button>

  )
}