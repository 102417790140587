// Chakra Imports
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { useHistory, useLocation } from 'react-router-dom';


export default function AdminNavbar(props) {



	const userdetails = JSON.parse(localStorage.getItem('userdetails'));

	const [scrolled, setScrolled] = useState(false);


	const history = useHistory();
	const location = useLocation();

	const [Slug, Setslug] = useState('')

	useEffect(() => {
		// Extract the slug from the URL
		const parts = location.pathname.split('/');
		const lastPart = parts[parts.length - 1];

		Setslug(lastPart)

	}, [location.pathname]);


	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});

	const { secondary, message, brandText } = props;

	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('navy.700', 'white');
	let secondaryText = useColorModeValue('gray.700', 'white');
	let navbarPosition = 'fixed';
	let navbarFilter = 'none';
	let navbarBackdrop = 'blur(50px)';
	let navbarShadow = 'none';
	let navbarBg = useColorModeValue('rgba(0, 0, 0, 0.1)', 'rgba(0,0,0,0.5)');
	let navbarBorder = 'transparent';
	let secondaryMargin = '0px';
	let paddingX = '15px';
	let gap = '0px';
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};


	const [formattedTime, setFormattedTime] = useState('');

	useEffect(() => {
		const getCurrentFormattedTime = () => {
			const now = new Date();
			const options = {
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
				timeZone: 'America/Chicago', // Adjust time zone as needed
			};
			const formattedTime = now.toLocaleString('en-US', options);
			setFormattedTime(formattedTime);
		};

		const intervalId = setInterval(getCurrentFormattedTime, 1000); // Update every second

		// Cleanup interval on component unmount
		return () => clearInterval(intervalId);
	}, []);

	return (
		<Box
		    zIndex='100'
			position={navbarPosition}
			boxShadow={navbarShadow}
			bg={navbarBg}
			borderColor={navbarBorder}
			filter={navbarFilter}
			backdropFilter={navbarBackdrop}
			backgroundPosition='center'
			backgroundSize='cover'
			borderRadius='16px'
			borderWidth='1.5px'
			borderStyle='solid'
			transitionDelay='0s, 0s, 0s, 0s'
			transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
			transition-property='box-shadow, background-color, filter, border'
			transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: 'center' }}
			display={secondary ? 'block' : 'flex'}
			minH='55px'
			justifyContent={{ xl: 'center' }}
			lineHeight='20.6px'
			mx='auto'
			mt='0px'
			pb='0px'
			right={{ sm: '10px', base: '10px', md: '30px', lg: '10px', xl: '10px' }}
			px={{
				sm: paddingX,
				md: '10px'
			}}
			ps={{
				xl: '12px'
			}}
			pt='0px'


			top={{ base: '5px', md: '16px', lg: '10px', xl: '0px' }}
			w={{
				base: 'calc(100vw - 10%)',
				sm: 'calc(100vw - -10%)',
				md: 'calc(100vw - 6%)',
				lg: 'calc(100vw - 4%)',
				xl: 'calc(100vw - 260px)',
				'2xl': 'calc(100vw - 260Px)'
			}}>
			<Flex
				w='100%'
				flexDirection={{
					sm: 'column',
					md: 'row'
				}}
				alignItems={{ xl: 'center' }}
				mb={gap}>
				<Box mb={{ sm: '0px', md: '15px', lg: '15px' }}
					mt={{ sm: '0px', md: '10px' }}
				>

					<Breadcrumb marginLeft='-10%' display={{ base: 'none', sm: 'none', lg: 'yes', md: 'yes', '2xl': 'yes' }}>
						<BreadcrumbItem color={secondaryText} fontSize='sm'>
							<BreadcrumbLink href='#/admin/default' color={secondaryText}>
								GoHighlevel Advisors
							</BreadcrumbLink>
						</BreadcrumbItem>

						<BreadcrumbItem color={secondaryText} fontSize='sm'>
							<BreadcrumbLink href='/#/admin/userprofile' color={secondaryText}>
								{userdetails.name}
							</BreadcrumbLink>
						</BreadcrumbItem>
						<BreadcrumbItem color={secondaryText} fontSize='sm' >
							<Text href='none' borderRadius='5px' px='5px' fontWeight='700' bg='linear-gradient(272deg, #ff3e3e, #9800ff)' color='white' fontFamily='monospace'>
								CST {formattedTime}
							</Text>
						</BreadcrumbItem>
					</Breadcrumb>
					{/* Here we create navbar brand, based on route name */}
					<Text
						display={{ base: 'none', sm: 'none', lg: 'yes', md: 'yes', '2xl': 'yes' }}

						color={mainText}
						bg='inherit'
						borderRadius='inherit'
						fontWeight='bold'
						fontSize='25px'
						_hover={{ color: { mainText } }}
						_active={{
							bg: 'inherit',
							transform: 'none',
							borderColor: 'transparent'
						}}
						_focus={{
							boxShadow: 'none'
						}}>
						{Slug == 'default' && 'Main Dashboard'}
						{Slug == 'today-stats' && 'Today Dashboard'}
						{Slug == 'this-month' && 'This Month Dashboard'}
						{Slug == 'userprofile' && 'User Profile'}
						{Slug == 'developerprofile' && 'Developer'}
						{Slug == 'leaderboard' && 'Leaderboard'}
						{Slug == 'report' && 'Report'}
						{Slug == 'last-month' && 'Last Month Report'}
						{Slug == 'analysis' && 'Analysis'}

					</Text>


					<Box
						ms='auto' w={{ sm: '150%', md: 'unset' }}
						ml={{ base: '10%' }}
						display={{ lg: 'none', md: 'none', '2xl': 'none' }}

					>

						<AdminNavbarLinks
							onOpen={props.onOpen}
							logoText={props.logoText}
							secondary={props.secondary}
							fixed={props.fixed}
							scrolled={scrolled}
						/>
					</Box>
				</Box>
				<Box ms='auto' w={{ md: 'unset' }}
					display={{ base: 'none', sm: 'none', lg: 'yes', md: 'yes', '2xl': 'yes' }}

				>

					<AdminNavbarLinks
						onOpen={props.onOpen}
						logoText={props.logoText}
						secondary={props.secondary}
						fixed={props.fixed}
						scrolled={scrolled}
					/>
				</Box>
			</Flex>
			{secondary ? <Text color='white'> Hiiiii.... Kabir this Side 😎 </Text> : null}
		</Box>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
