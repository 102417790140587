
import {
    Flex,
    Box,
    Link,
    Avatar,

    Text,
    useColorModeValue,
    SimpleGrid,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { SlReload } from "react-icons/sl";

import React, { useState, useRef, useEffect } from "react";

// Custom components
import Card from "components/card/Card";
export default function () {

    const data = JSON.parse(localStorage.getItem('user'));
    const userdetails = JSON.parse(localStorage.getItem('userdetails'));

    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const [firstDateOfLastMonth, setFirstDateOfLastMonth] = useState(null);
    const [lastDateOfLastMonth, setLastDateOfLastMonth] = useState(null);

    useEffect(() => {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

        setFirstDateOfLastMonth(firstDayOfMonth.toDateString());
        setLastDateOfLastMonth(lastDayOfMonth.toDateString());
    }, []); // Run only once on component mount


    return (
        <Card
            margin='auto'
            direction='column'
            w='100%'
            p='10px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <SimpleGrid width='100%' px='20px' margin='auto' columns={{ base: 1, md: 1, lg: 1, xl: 1 }}
                gap='15px'
                m='auto'
            >


                <Box height='100%' width='100%' borderRadius='10px' bg={boxBg} px='10%' py='2%'   >

                    <SimpleGrid w={{ base: '95%', md: '95%', lg: '95%', "2xl": '95%' }}
                        gap='5px' columns={{ base: 2, md: 2, lg: 2, xl: 2 }} >
                        <Flex alignItems="center">
                            <Avatar
                                marginRight="10px"
                                src={userdetails.dp}
                                h="45px"
                                w="45px"
                                border="2px solid green"
                            />
                            <Text
                                fontSize={{ base: 10, md: 12, lg: 18, xl: 18 }}
                                fontWeight="700"
                                lineHeight="45px" // Vertically centering the text with the avatar
                            >
                                {userdetails.name}
                            </Text>
                        </Flex>

                        <Text fontWeight='700' fontSize={{ base: 10, md: 10, lg: 15, xl: 15 }} style={{ display: 'flex', alignItems: 'center' }}>
                            {firstDateOfLastMonth && <Text color={brandColor}> {firstDateOfLastMonth} </Text>}
                            <Text >&nbsp; to &nbsp;</Text>
                            {lastDateOfLastMonth && <Text color={brandColor} >{lastDateOfLastMonth}</Text>
                            }
                        </Text>


                    </SimpleGrid>
                </Box>


            </SimpleGrid>


        </Card>
    );
}
