// Login.js

import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from 'layouts/admin';
import Sign from '../Signin';


const Signmain = () => {

   

    let data = localStorage.getItem("token");
	const userData = JSON.parse(localStorage.getItem('user'));

    

    return (
        <div>
            {data == null ? <Sign />
                :
               
                <Switch>

                    <Route path={`/admin`} component={AdminLayout} />
                    <Redirect from='/' to='/admin' />
                </Switch>

            }
        </div>
    );
};

export default Signmain;
