import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Avatar,
    useColorModeValue,
    TableCaption,
    TableContainer, Tfoot, Box
  } from "@chakra-ui/react";
  import React, { useMemo } from "react";
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";
  import { SlReload } from "react-icons/sl";

  
  import '../Leaderboard.css'
  
  // Custom components
  import Card from "components/card/Card";
  import Menu from "components/menu/MainMenu";

  import { PiArrowElbowDownLeftBold } from "react-icons/pi";
  export default function Ob(props) {
  
    const userdetails = JSON.parse(localStorage.getItem('userdetails'));

  
  
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    return (
      <Card

      className='leaderboardbox'
        direction='column'
        w='100%'
        px='0px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex width='100%' className="Rankmonthly" height='40px'>
          <Text
          margin='auto'
            color={textColor}
            fontSize='20px'
            fontWeight='700'
            lineHeight='100%'>
            Total Calls This Month
          </Text>
        </Flex>

        {  props?.Apidata == undefined ? <Box height='110px'> <SlReload  className="loading-iconframe" /> </Box> :

<TableContainer overflowX="hidden"  >
<Table   size='sm' variant='simple' >
  <Thead  >
    <Tr  >
      <Th color='gray.400' >Rank</Th>
      <Th color='gray.400' > Name</Th>
      <Th color='gray.400'  >Total Calls</Th>
    </Tr>
  </Thead>
  <Tbody>
    {props.Apidata
      .sort((a, b) => b.totalcallsmonth - a.totalcallsmonth) // Sort in descending order
      .map((row, index) => (
        row.totalcallsmonth !== '0' &&   <Tr className={row.name == userdetails?.name && 'Rank'} key={index}>
          <Td>{index + 1}</Td>
          <Td>
                                            <Text style={{ display: 'flex', alignItems: 'center' }}>
                                            <Box
                          bgGradient="linear(to-tr, teal.300, blue.500)"
                          borderRadius="full"
                          p="2px"  // Add padding to prevent gradient clipping
                          display="inline-block"
                          mr='10px'
                        >
                          <Avatar
                            color="white"
                            bg='linear(to-tr, teal.300, blue.500)'
                            src={row?.dp}
                            name={row?.name}
                            fontFamily="monospace"
                            size="sm"
                            w="25px"
                            h="25px"
                          />
                        </Box>                                                        {row.name}
                                                {row.name == userdetails?.name && <PiArrowElbowDownLeftBold style={{ fontWeight:'800' , fontSize:'18px' , marginLeft:'10px' ,  color:' #654caf' }} /> }   

                                            </Text>
                                        </Td>     
          <Td >{row.totalcallsmonth} <Text className="tablesecondarytext" > {row.monthlyworkingdays} Days </Text></Td>
        </Tr>
      ))}
  </Tbody>
</Table>
</TableContainer>

          
}
  
     
  
      </Card>
    );
  }