import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome
} from "react-icons/md";
import { MdCalendarMonth } from "react-icons/md";

import { FaLaptopCode} from "react-icons/fa";
import { MdOutlineLeaderboard } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { TbCalendarRepeat } from "react-icons/tb";

// Admin Imports
import MainDashboard from "views/admin/allstats";
import Profile from "views/admin/profile";
import DataTables from "views/admin/today";
import Month from "views/admin//thismonth";
import Userprofile from "./views/admin/userprofile";
import Monthlyreport from './views/admin/monthlyreport'
// Auth Imports
import MainLeaderboard from "views/admin/leaderboard/Mainindex";
import LastMonth from "views/admin/lastmonth";
import Analysis from "views/admin/Anaylsis";
import { SlGraph } from "react-icons/sl";



const data = JSON.parse(localStorage.getItem('userdetails'));


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },

  {
    name: "Today Call Stats",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/today-stats",
    component: DataTables,
  },
  {
    name: "This Month",
    layout: "/admin",
    icon: <Icon as={MdCalendarMonth} width='20px' height='20px' color='inherit' />,
    path: "/this-month",
    component: Month,
  },

  {
    name: "Last Month",
    layout: "/admin",
    icon: <Icon as={TbCalendarRepeat} width='20px' height='20px' color='inherit' />,
    path: "/last-month",
    component: LastMonth,
  },
  {
    name: "Analysis"  ,
    layout: "/admin",
    path: "/analysis",
    icon: <Icon as={SlGraph
  } width='20px' height='20px' color='inherit' />,
    component: Analysis ,
  },
  {
    name: "Report"  ,
    layout: "/admin",
    path: "/report",
    icon: <Icon as={TbReportAnalytics
  } width='20px' height='20px' color='inherit' />,
    component: Monthlyreport ,
  },

  data?.leaderboard === 'enabled' &&
  {
    name: "Leaderboard",
    layout: "/admin",
    path: "/leaderboard",
    icon: <Icon as={MdOutlineLeaderboard
  } width='20px' height='20px' color='inherit' />,
    component: MainLeaderboard,
  }

,
  {
    name: "Profile",
    layout: "/admin",
    path: "/userprofile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Userprofile ,
  },


  {
    name: "Developer",
    layout: "/admin",
    path: "/developerprofile",
    icon: <Icon as={FaLaptopCode
  } width='20px' height='20px' color='inherit' />,
    component: Profile,
  },

];

export default routes;
