import React, { useEffect, useState } from "react";

import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  
} from "@chakra-ui/react";
import { Button, ButtonGroup } from '@chakra-ui/react'

import Leaderboard from "./index";
import { IoIosSwitch } from "react-icons/io";
import Sortby from "./components/Sortby/Sortby"
import TeamRank from './components/Teamranking/index'







function MainLeaderboard() {

  let Apidataaaa = JSON.parse(localStorage.getItem('leaderboard'));
  const data = JSON.parse(localStorage.getItem('userdetails'));
  const [activeButton, setActiveButton] = useState('ALL');

  const [Apidata,setapi] =useState(Apidataaaa)
  const [view,setview] = useState("ALL")
  const Api = process.env.REACT_APP_LEADERBOARD_ENDPOINT

  useEffect(() => {
		const fetchleaderboard = async () => {
			try {
				const response = await fetch(Api);
				const result = await response.json();
        setapi(result);
				localStorage.setItem('leaderboard', JSON.stringify(result));  
				} catch (error) {
				console.error('Error fetching data:', error);
				}
		};
		fetchleaderboard();
	  },[]);


    const toggleState = () => {
      if (view === 'ALL') {
        setview('SORT BY TEAM');
      } else if (view === 'SORT BY TEAM') {
        setview('TEAM RANK');
      } else {
        setview('ALL');
      }
      };
  
  
  // Empty dependency array to run the effect only once on component mount
if(data?.leaderboard
  == 'enabled' )
  return (
    <Box  borderRadius='10px' width='100%' padding={{ base: 'null', lg: '10px', }} mt={{ sm: '50px', md: '60px', lg: '60px' }} >


  <div style={{ textAlign: 'right', marginBottom: '10px' }}>
  <div style={{ marginBottom: '10px' }}>
  <Button   onClick={toggleState}  textAlign="right" rightIcon={<IoIosSwitch />} colorScheme='purple' variant='solid'>
  {view}
  </Button>
    
  </div>
  <Text fontSize='10px' fontWeight='600' > Switch to see Rank of one Particular team.</Text>
</div>

        


     {view === "ALL" ? <Leaderboard/> : view === "SORT BY TEAM" ? <Sortby/> : <TeamRank/>}

     




    
  </Box>



   
  )
}


export default MainLeaderboard;