
import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";
import CheckTable from "./components/CheckTable";
import ColumnsTable from "./components/ColumnsTable";
import ComplexTable from "./components/ComplexTable";
import { Helmet } from "react-helmet";

import React, { useState } from "react";
import Userdailyreport from './components/Userdailyreport'

export default function Month() {

  const data = JSON.parse(localStorage.getItem('user'));

  const [show, setshow] = useState(true)

  const containerStyle = {
    borderRadius: '10px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    background: '#2b5876',  /* fallback for old browsers */
    background: ' -webkit-linear-gradient(to right, #4e4376, #2b5876)',  /* Chrome 10-25, Safari 5.1-6 */
    background: ' linear-gradient(to right, #4e4376, #2b5876)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    // Add any other styles as needed
  };

  const messageStyle = {
    color: '#fff', // Set the text color
    fontSize: '30px', // Adjust the font size as needed
    textAlign: 'center', // Center the text
  };


  // Chakra Color Mode
  return (
    <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>

      <Helmet>
        <title> This Month - Advisors stats</title>
      </Helmet>

      {data.output[0].Total_calls_Monthly == 0 ?
        <Box mt='0px' pt={{ base: "60px", md: "60px", xl: "60px" }}  >
          <div style={containerStyle}>
            <div style={messageStyle}>
              <h1 textAlign='center' > You have no calls this month !!</h1>
            </div>
          </div>

        </Box> :



        <>
          <Userdailyreport />
          <DevelopmentTable />
          <SimpleGrid
            mt='0px'
            columns={{ sm: 1, md: 2 }}
            spacing={{ base: "20px", xl: "20px" }}>

            <CheckTable />
            <ComplexTable />
          </SimpleGrid>
          <ColumnsTable
          />
        </>

      }

    </Box>
  );
}
