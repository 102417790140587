import React, { useState } from "react";
import { Box, Flex, Text, SimpleGrid, Button } from "@chakra-ui/react";
import Ob from './components/Monthly/Ob'
import Qa from './components/Monthly/Qa'
import Hours from './components/Monthly/Hours'
import Totalcalls from './components/Monthly/Totalcalls'
import Mostcalltime from './components/Alltime/Mostcalltime'
import Mostcalls from './components/Alltime/Mostcalls'
import MostOb from './components/Alltime/MostOb'
import Mostqa from './components/Alltime/Mostqa'
import Averagecallsmonthly from './components/Monthly/Averagecallsmonthly'
import Averagecalls from './components/Alltime/Averagecalls'
import Todaymostcalls from "./components/Today/Todaymostcalls";
import Totalcalltime from './components/Today/Totalcalltime'
import RankOb from "../Anaylsis/Components/RankOb"
import Rankallcalls from "../Anaylsis/Components/Rankallcalls"
import Workinghours from "../Anaylsis/Components/Whrank"
import Bas from "../leaderboard/components/Monthly/Bas"
import ObBas from "../leaderboard/components/Monthly/ObBas"

import { Helmet } from "react-helmet";


function Leaderboard() {
  const [activeButton, setActiveButton] = useState("Today");
  const Apidata = JSON.parse(localStorage.getItem("leaderboard"));
  const data = JSON.parse(localStorage.getItem("userdetails"));

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  return (
    <Box>
      <Helmet>
        <title> Leaderboard - Advisors stats</title>
      </Helmet>
      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap="20px" mb="20px">
        <RankOb />
        <Rankallcalls />
        <Workinghours />
      </SimpleGrid>

      <div className="switch-container">
        {["All", "Today", "This Month", "Alltime"].map((buttonNumber) => (
          <button
            key={buttonNumber}
            className={`switch-button ${activeButton === buttonNumber ? 'active' : ''}`}
            onClick={() => handleButtonClick(buttonNumber)}
          >
            {buttonNumber}
          </button>
        ))}
      </div>

      {(activeButton === "Today" || activeButton === "All") && (
        <Box borderRadius="10px" paddingBottom="40px" bgGradient="linear(to-r, #E2E2E2, #C9D6FF)">
          <Flex className="leaderboardheading" margin="auto" marginTop="20px" textAlign="center" height="50px" mb="20px" borderRadius="10px">
            <Text margin="auto" color="white" fontSize="25px" fontWeight="700" lineHeight="100%">
              TODAY LEADERBOARD
            </Text>
          </Flex>
          <SimpleGrid margin="auto" columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }} w="95%" gap="25px">
            <Totalcalltime Apidata={Apidata} />
            <Todaymostcalls Apidata={Apidata} />
          </SimpleGrid>
        </Box>
      )}

      {(activeButton === "This Month" || activeButton === "All") && (
        <Box borderRadius="10px" paddingBottom="20px" bgGradient="linear(to-r, #E2E2E2, #C9D6FF)">
          <Flex className="leaderboardheading1" margin="auto" marginTop="20px" textAlign="center" height="50px" mb="20px" borderRadius="10px">
            <Text margin="auto" color="WHITE" fontSize="25px" fontWeight="700" lineHeight="100%">
              THIS MONTH LEADERBOARD
            </Text>
          </Flex>
          <SimpleGrid margin="auto" columns={{ base: 1, md: 2, lg: 2, "2xl": 3 }} w="95%" gap="25px">
            <ObBas Apidata={Apidata} />
            <Totalcalls Apidata={Apidata} />
            <Hours Apidata={Apidata} />
            <Qa Apidata={Apidata} />
            <Averagecallsmonthly Apidata={Apidata} />
            <Ob Apidata={Apidata} />
            <Bas Apidata={Apidata} />
          </SimpleGrid>
        </Box>
      )}

      {(activeButton === "Alltime" || activeButton === "All") && (
        <Box borderRadius="10px" paddingBottom="20px" bgGradient="linear(to-r, #E2E2E2, #C9D6FF)">
          <Flex className="leaderboardheading3" margin="auto" marginTop="20px" textAlign="center" height="50px" mb="20px" borderRadius="10px">
            <Text margin="auto" color="white" fontSize="25px" fontWeight="700" lineHeight="100%">
              ALL TIME LEADERBOARD
            </Text>
          </Flex>
          <SimpleGrid margin="auto" columns={{ base: 1, md: 2, lg: 2, "2xl": 3 }} w="95%" gap="25px">
            <Mostcalls Apidata={Apidata} />
            <Mostcalltime Apidata={Apidata} />
            <MostOb Apidata={Apidata} />
            <Mostqa Apidata={Apidata} />
            <Averagecalls Apidata={Apidata} />
          </SimpleGrid>
        </Box>
      )}
    </Box>
  );
}

export default Leaderboard;
