import React from "react";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Text

} from '@chakra-ui/react'

import { FaCoffee } from "react-icons/fa";






function Buyme() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Button borderRadius='5px' size='sm'  rightIcon={<FaCoffee />}  colorScheme='green' variant="solid" marginTop='10px'  marginBottom='10px'  onClick={onOpen} >Buy me Chai
            </Button>

            <Modal motionPreset='slideInBottom'  isOpen={isOpen} onClose={onClose}>
                <ModalOverlay backdropFilter='blur(10px) hue-rotate(10deg)'  />
                <ModalContent  height='600px'  zIndex='100' margin='auto'>
                    <ModalHeader  textAlign='center' > Woahhh !! Thank You ! 🥰 </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody style={{textAlign:'center' , width:'300px'}} margin='auto' >
                        Hi, Buy your developer some Chai-sutta So that he can bring more updates for you.
                        Thanks ! 😅
                        <img alt="QR Code"  src='https://i.postimg.cc/9MHLwNQ8/Picsart-24-01-21-08-35-34-053-removebg-preview-50g-UJTmsi-transformed.png'></img>
                    </ModalBody>

                    <ModalFooter  margin='auto'>
                        <Button onClick={onClose}   colorScheme='blue' > Done !!</Button>
                    </ModalFooter>

                </ModalContent>
            </Modal>
        </>
    )
}

export default Buyme;