

import React, { useEffect, useState, Fragment, useRef } from "react";
import { BiSupport } from "react-icons/bi";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { PiClockFill } from "react-icons/pi";
import { FcMusic } from "react-icons/fc";
import {

  Link,
  Text,
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import { TbLoader } from "react-icons/tb";
import { WiDayCloudy } from "react-icons/wi";
import { FcMissedCall } from "react-icons/fc";



// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdOutlineQueryBuilder,
  MdAvTimer,
  MdBarChart,
} from "react-icons/md";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { BsClockHistory } from "react-icons/bs";






export default function UserReports() {


  const [hide, sethide] = useState(false);


  const data = JSON.parse(localStorage.getItem('user'));



  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const mobReport = parseFloat(data.output[0].mob_report) || 0;
  const reportob = parseFloat(data.output[0].reportob) || 0;
  
  const TotaloB = (mobReport === 0 || reportob === 0)
                  ? (mobReport !== 0 ? mobReport : reportob)
                  : mobReport + reportob;
  



  return (
    <Box  mb='10px'  mt='0px' pt={{ base: "10px", md: "10px", xl: "10px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}

        gap='15px'
        mb='20px'>
        <MiniStatistics
        
        

          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Calls'
          value={




            <div className="stats">

              {data.output[0].reportallcalls == null ? <TbLoader
                className="loading-icon" /> : ''


              }
              {data.output[0].reportallcalls && (
                <div>

                  {data !== 0 ? <Text style={{ marginLeft: '5px' }}> {data.output[0].reportallcalls}  </Text>

                    : ''}


                </div>
              )}


            </div>
          }

        />




        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdOutlineQueryBuilder} color={brandColor} />
              }
            />
          }
          name='Total Call Hours'
          value={<div className="stats">
            {data.output[0].reporttotalhours == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}> {data.output[0].reporttotalhours} <span style={{ fontSize: '12px', color: 'grey' }}> out of </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].reportactualworkinghours} </span>&nbsp;<span style={{ fontSize: '10px' }}>{data.output[0].reporttotalhourpercent} </span> </Text>

                  : ''}

                {/* You can add more <h1> elements for other properties as needed */}
              </div>
            )}
          </div>}

        />
        <MiniStatistics

          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAvTimer} color={brandColor} />
              }
            />
          }

          name='Average no. of calls ' value=
          {<div className="stats">
            {data.output[0].reportaveragecalls == null ? <TbLoader
              className="loading-icon" /> : ''}
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}> {data.output[0].reportaveragecalls}</Text>

                  : ''}

                {/* You can add more <h1> elements for other properties as needed */}
              </div>
            )}
          </div>} />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='24px' h='24px' as={PiClockFill} color={brandColor} />}
            />
          }
          name='Avg. call Hour'
          value={<div className="stats">

            {data.output[0].reportaveragehour == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].reportaveragehour}</Text>

                  : ''}

                {/* You can add more <h1> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(to right, #f2709c, #ff9472);'
              icon={<Icon w='38px' h='38px' as={WiDayCloudy
              } color='white' />}
            />
          }
          name='Total working days'
          value={<div className="stats">

            {data.output[0].reportworkingdays == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

{data.output.length > 0 ? <Text style={{ marginLeft: '0' }}> {data.output[0].reportworkingdays} <span style={{ fontSize: '8px', color: 'grey' }} >days</span> <span style={{ fontSize: '10px', color: 'grey' }}>  Since </span>  &nbsp;<span style={{ fontSize: '12px' }}>{data.output[0].reportfromdate} </span>&nbsp;<span style={{ fontSize: '10px', color: 'grey' }}>{data.output[0].reportdatasince} Days</span>   </Text>

                  : ''}

                {/* You can add more <h1> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />







        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FcMusic
                } color={brandColor} />
              }
            />
          }
          name= {
          
            <Text style={{ marginBottom:'0px' , display: 'flex', alignItems: 'center' }}>
            Hours without Call 
            <Link onClick={() => sethide(prevState => !prevState)} style={{ marginLeft: '10px' }}>
              { hide ?  <BiShow fontSize='20px' /> : <BiHide  fontSize='20px' />}
            </Link>
          </Text>
           }
          value={<div className="stats">

          {data.output[0].reporthoursnocalls == null && <TbLoader
            className="loading-icon" />


          }
          {data && (
            <div>

             {hide  ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].reporthoursnocalls} <span style={{ fontSize: '10px', color: 'grey' }}> out of </span>  <span style={{ marginLeft: '5px', fontSize: '15px', color: '#95A5A6' }} >   {data.output[0].reportactualworkinghours} </span></Text>

: <Text style={{ marginLeft: '5px' }}  > ******** </Text>}
       


              {/* You can add more <Text> elements for other properties as needed */}
            </div>
          )}
        </div>}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={<Icon w='24px' h='24px' as={TbDeviceDesktopAnalytics} color={brandColor} />}
            />
          }
          name='Total OB'
          value={
            <div className="stats">


              <div>

                {data.output[0].reportob == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].reportob}  <span style={{ fontSize: '12px', color: 'grey' }}> average time </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].reportobaveragetime}    <span style={{ fontSize: 'sm', color: 'grey' }}> / {data.output[0].reportobperc} </span>    </span> </Text>
                }


              </div>

            </div>


          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={<Icon w='24px' h='24px' as={BiSupport} color={brandColor} />}
            />
          }
          name='Total Q&A'
          value={
            <div className="stats">


              <div>

                {data.output[0].reportqa == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].reportqa}  <span style={{ fontSize: '12px', color: 'grey' }}> average time </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].reportqaaveragetime}   <span style={{ fontSize: 'sm', color: 'grey' }}> / {data.output[0].reportqaperc} </span> </span>   </Text>
                }


              </div>

            </div>


          }
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={<Icon w='24px' h='24px' as={FiSettings} color={brandColor} />}
            />
          }
          name='Total BAS'
          value={
            <div className="stats">


              <div>

                {data.output[0].reportbas == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].reportbas}   <span style={{ fontSize: '12px', color: 'grey' }}> average time </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].reportbasaveragetime}   <span style={{ fontSize: 'sm', color: 'grey' }}> / {data.output[0].reportbasperc} </span> </span> </Text>
                }


              </div>

            </div>


          }
        />





      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }}
        gap='20px'
        mb='20px'
        marginX={{ base: 0, md: '2%', lg: '2%', "2xl": '2%' }}
      >
        
         <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='36px' h='36px' as={BsClockHistory} color={brandColor}/>}
            />
          }
          name='Average Working hours'
          value={<div className="stats">

            {data.output[0].at_report == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].at_report}</Text>

                  : ''}

                {/* You can add more <Text> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />
         <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={<Icon w='28px' h='28px' as={FcMissedCall} color={brandColor} />}
            />
          }
          name='Missed Onboarding in The Report'
          value={
            <div className="stats">


              <div>

                {data.output[0].reportbas == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].mob_report}   <span style={{ fontSize: '12px', color: 'grey' }}> out of </span> 
                   <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {TotaloB}  <span style={{ fontSize: 'sm', color: 'grey' }}>/ {data.output[0].mob_reportperc} </span> </span> </Text>
                }


              </div>

            </div>


          }
        />

<MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={<Icon w='28px' h='28px' as={FcMissedCall} color={brandColor} />}
            />
          }
          name='Total Overtime hours'
          value={
            <div className="stats">


              <div>

                {data.output[0].reportbas == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}>
                  {data?.output[0]?.report_overtime !== 0 ? data.output[0].report_overtime : '0:00:00'}
                </Text>
                
                }


              </div>

            </div>


          }
        />

      </SimpleGrid>


    </Box>
  );
}











