import React from "react";

import {
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Link,
    useColorModeValue

} from '@chakra-ui/react'

import { FaCoffee } from "react-icons/fa";
import Buyme from '../Buyme/Buymecoffe'

const Noti = ({ isOpen, onClose }) => {

    const websitedetails = JSON.parse(localStorage.getItem('websitedetails'));
    const brandColor = useColorModeValue("brand.500", "white");



    return (
        <>
            {/* <Button  colorScheme='rgb(63,94,251);' variant='solid' marginBottom='20px' onClick={onOpen} >Buy me Chai &nbsp;  <FaCoffee size='20px' color="white" />
            </Button> */}

            <Modal  isOpen={isOpen} onClose={onClose}>
                <ModalOverlay      
  />
                <ModalContent borderRadius='20px'  margin='auto'>
                    <ModalHeader borderRadius='20px 20px 0 0'  bg={brandColor} textAlign='center'  color='white'> Announcement 🎉🥳 </ModalHeader>
                    <ModalCloseButton color='white' />
                    <ModalBody  style={{ textAlign: 'center', width: '100%' }} margin='auto' >
                       
               
                      <Text mt='20px' fontWeight='500' >   {websitedetails[0].notificationmsg} </Text>

                                         
                        </ModalBody>


                    <ModalFooter m='auto'>
                        <Buyme />
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Noti;