
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Projects from "views/admin/profile/components/Projects";

// Assets
import banner from "assets/img/auth/banner.png";
import React from "react";
import { Helmet } from "react-helmet";


export default function Overview() {
  return (
    <Box pt={{ base: "50px", md: "80px", xl: "60px" }}>
      <Helmet>
        <title> Developer - Advisors stats</title>
      </Helmet>

      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr ",
        }}
        templateRows={{
          base: "repeat(1fr, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>

        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={banner}
          name='King Raj'
          job='Customer Success'

        />
        
      </Grid>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "repeat(2, 1fr)",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(1, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Projects
          gridArea='1 / 2 / 2 / 2'
          banner={banner}
          
        />
        <General
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 2" }}
          minH='365px'
          pe='20px'
        />
       
      </Grid>
    </Box>
  );
}
