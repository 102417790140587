/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import {
  Button,
  Text,
  Box,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets

import { useRef } from 'react';
import generatePDF from 'react-to-pdf';
import { FaCloudDownloadAlt } from "react-icons/fa";

// Custom components
import Allthreemonth from "./Components/allthreemonth"
import Threemonthob from "./Components/threemonthob"
import Threemonthhour from "./Components/Threemonthworkinghour"
import Bas from "./Components/Bas"
import Averagecalls from "./Components/averagecalls"
import Workingdays from "./Components/Workingdays"
import Missedob from "./Components/MOB"
import Qa from "./Components/qa"
import ObBas from "./Components/ObBas"
import { Helmet } from "react-helmet";



export default function Analysis() {


  const targetRef = useRef();


  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");



  return (
    <Box  mt='0px' pt={{ base: "40px", md: "80px", xl: "80px" }}
    >
      <Helmet>
        <title> Analysis - Advisors stats</title>
      </Helmet>

      <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <div style={{ marginBottom: '10px' }}>
          <Button
            rightIcon={<FaCloudDownloadAlt />}
            colorScheme='green'
            onClick={() => generatePDF(targetRef, { filename: 'Last_3_month_analysis.pdf' })}
          >
            Download
          </Button>
        </div>
        <Text fontSize='10px' fontWeight='600' > Keep your screen zoom to 70% for better pdf result. </Text>
      </div>
      <SimpleGrid ref={targetRef} columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px' >
        <Allthreemonth />
        <ObBas/>
        <Threemonthob />
        <Bas />
        <Missedob />
        <Qa />
        <Averagecalls />

        <Threemonthhour />
        <Workingdays />



      </SimpleGrid>


    </Box>
  );
}











