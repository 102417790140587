

export async function websitedetails_api() {
    const API_WEBSITEDETAILS = process.env.REACT_APP_API_ENDPOINT;

    try {
      const response = await fetch(API_WEBSITEDETAILS);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  