import React, { useEffect, useState } from "react";

import {
  Box,
  Flex,
  Text,
  SimpleGrid,
  Button,

} from "@chakra-ui/react";
import Ob from './Monthly/Ob'
import Qa from './Monthly/Qa'
import Hours from './Monthly/Hours'
import Totalcalls from './Monthly/Totalcalls'
import Mostcalltime from './Alltime/Mostcalltime'
import Mostcalls from './Alltime/Mostcalls'
import MostOb from './Alltime/MostOb'
import Mostqa from './Alltime/Mostqa'
import Averagecallsmonthly from './Monthly/Averagecallsmonthly'
import Averagecalls from './Alltime/Averagecalls'
import Todaymostcalls from "./Today/Todaymostcalls";
import Totalcalltime from './Today/Totalcalltime'
import { Select } from '@chakra-ui/react'
import toast from "react-hot-toast";
import { IoRefreshCircleSharp } from "react-icons/io5";



function Sortby() {

  let Apidataaaa = JSON.parse(localStorage.getItem('teamleaderboard'));
  let leaderboardAllData = JSON.parse(localStorage.getItem('leaderboard'));
  const data = JSON.parse(localStorage.getItem('userdetails'));
  const [activeButton, setActiveButton] = useState('This Month');
  const [Apidata, setapi] = useState(Apidataaaa)
  const [team, setteams] = useState("")
	const Apileaderboard = process.env.REACT_APP_LEADERBOARD_ENDPOINT





  const defaultTeam = Apidataaaa !== null ? Apidataaaa[0]?.team : 'Select';
  const [selectedTeam, setSelectedTeam] = useState(defaultTeam);

  const containerStyle = {
    borderRadius: '10px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
    background: '#360033', /* fallback for old browsers */
    background: '-webkit-linear-gradient(to right, #0b8793, #360033)',  /* Chrome 10-25, Safari 5.1-6 */
    background: 'linear-gradient(to right, #0b8793, #360033)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    // Add any other styles as needed
  };

  const messageStyle = {
    color: '#fff', // Set the text color
    fontSize: '30px', // Adjust the font size as needed
    textAlign: 'center', // Center the text
  };


  const handleTeamChange = (event) => {
    fetchleaderboard(event);
    setSelectedTeam(event.target.value);

  };


  const fetchleaderboard = async (event) => {
    try {
      
      const teamData = leaderboardAllData.filter(item => item.team.toLowerCase() === event.target.value);
      localStorage.setItem('teamleaderboard', JSON.stringify(teamData));
      setapi(teamData);
      toast.dismiss()
      toast.success("Loaded");
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const refresh = async () => {
    try {
      toast.loading("Refreshing");
      const response = await fetch(Apileaderboard);
      const result = await response.json();
      const teamData = result.filter(item => item.team.toLowerCase() === selectedTeam);
      localStorage.setItem('teamleaderboard', JSON.stringify(teamData));
      setapi(teamData);
      toast.dismiss()
      toast.success("Refreshed")
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  useEffect(() => {


    const names = leaderboardAllData.map(item => item.team);
    const uniqueNames = Array.from(new Set(names)).sort();
    setteams(uniqueNames);

    const fetchleaderboard = async (event) => {
      try {

        const response = await fetch(Apileaderboard);
        const result = await response.json();
        const teamData = result.filter(item => item.team.toLowerCase() === selectedTeam);
        localStorage.setItem('teamleaderboard', JSON.stringify(teamData));
        setapi(teamData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    selectedTeam !== 'Select' && fetchleaderboard();




  }, []);

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };


  // Empty dependency array to run the effect only once on component mount
  if (data?.leaderboard
    == 'enabled')
    return (
      <Box >


        {selectedTeam !== 'Select' ? <div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
      {selectedTeam !== 'Select' && team.length > 0 && (
        <Select
          width={{ base: '100px', xl: '200px', md: '200px' }}
          bg='white'
          marginTop='-70px'
          borderColor='#DBDDDB'
          boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
          variant='filled'
          onChange={handleTeamChange}
          value={selectedTeam}
        >
  {selectedTeam == 'Select' && <option value="" >Select Your Team</option>}          {team.map((team, index) => (
            <option key={index} value={team}>{team.toUpperCase()}</option>
          ))}
        </Select>
      )}
      
      <Button
        marginTop='-70px'
        colorScheme='transparent'
        size='l'
        onClick={refresh}
        marginLeft='5px' // Adjust the spacing between the select and button as needed
      >
        <IoRefreshCircleSharp fontSize='30px' color="blue" />
      </Button>
    </div>


          <div className="switch-container">
            {['All', 'Today', 'This Month', 'Alltime'].map((buttonNumber) => (
              <button
                key={buttonNumber}
                className={`switch-button2 ${activeButton === buttonNumber ? 'active' : ''}`}
                onClick={() => handleButtonClick(buttonNumber)}
              >
                {buttonNumber}
              </button>
            ))}
          </div>

          <>
            {/* //First dashboard */}



            {(activeButton == 'Today' || activeButton === 'All') &&

              <Box borderRadius='10px' paddingBottom='40px' bg='
            background: #1F1C2C;  /* fallback for old browsers */
            background: -webkit-linear-gradient(to right, #928DAB, #1F1C2C);  /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to right, #928DAB, #1F1C2C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            
            
            '>

                <Flex className="leaderboardheading" margin='auto' marginTop='20px'
                  textAlign='center' height='60px' mb='20px' borderRadius='10px'>


                  <Text
                    fontFamily='roboto'
                    letterSpacing='2px'
                    margin='auto'
                    color='white'
                    fontSize='20px'
                    fontWeight='700'
                    lineHeight='100%'>
                    TODAY

                    <Text letterSpacing='5px' fontFamily='monospace' fontSize="10px" > {selectedTeam}'s Leaderboard </Text>

                  </Text>

                </Flex>

                <SimpleGrid
                  margin='auto'

                  columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
                  w={{ base: '95%', md: '95%', lg: '95%', "2xl": '95%' }}
                  gap='25px'>
                  {<Totalcalltime Apidata={Apidata} />}
                  {<Todaymostcalls Apidata={Apidata} />}
                </SimpleGrid>
              </Box>


            }

            {/* //second dashboard */}

            {
              (activeButton == 'This Month' || activeButton === 'All') &&
              <Box borderRadius='10px' paddingBottom='20px' bg='
            background: #1F1C2C;  /* fallback for old browsers */
            background: -webkit-linear-gradient(to right, #928DAB, #1F1C2C);  /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to right, #928DAB, #1F1C2C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            ' >
                <Flex className="leaderboardheading1" margin='auto' marginTop='20px'
                  textAlign='center' height='60px' mb='20px' borderRadius='10px'>
                  <Text
                    fontFamily='roboto'
                    letterSpacing='2px'
                    margin='auto'
                    color='WHITE'
                    fontSize='20px'
                    fontWeight='700'
                    lineHeight='100%'>
                    THIS MONTH
                    <Text letterSpacing='5px' fontFamily='monospace' fontSize="10px" > {selectedTeam}'s Leaderboard </Text>

                  </Text>


                </Flex>

                <SimpleGrid
                  margin='auto'
                  columns={{ base: 1, md: 2, lg: 2, "2xl": 3 }}
                  w={{ base: '95%', md: '95%', lg: '95%', "2xl": '95%' }}
                  gap='25px'>

                  {<Totalcalls Apidata={Apidata} />}
                  {<Hours Apidata={Apidata} />}

                  {<Qa Apidata={Apidata} />}
                  {<Ob Apidata={Apidata} />}
                  {<Averagecallsmonthly Apidata={Apidata} />}
                </SimpleGrid>

              </Box>
            }

            {/* //third dashboard */}

            {
              (activeButton == 'Alltime' || activeButton === 'All') &&
              <Box borderRadius='10px' paddingBottom='20px' bg='
            background: #1F1C2C;  /* fallback for old browsers */
            background: -webkit-linear-gradient(to right, #928DAB, #1F1C2C);  /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to right, #928DAB, #1F1C2C); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
            ' >
                <Flex className="leaderboardheading3" margin='auto' marginTop='20px'
                  textAlign='center' height='60px' mb='20px' borderRadius='10px'>
                  <Text
                    fontFamily='roboto'
                    letterSpacing='2px'
                    margin='auto'
                    color='white'
                    fontSize='20px'
                    fontWeight='700'
                    lineHeight='100%'>
                    All TIME
                    <Text letterSpacing='5px' fontFamily='monospace' fontSize="10px" > {selectedTeam}'s Leaderboard </Text>

                  </Text>


                </Flex>

                <SimpleGrid
                  margin='auto'
                  columns={{ base: 1, md: 2, lg: 2, "2xl": 3 }}
                  w={{ base: '95%', md: '95%', lg: '95%', "2xl": '95%' }}
                  gap='25px'>
                  {<Mostcalls Apidata={Apidata} />}
                  {<Mostcalltime Apidata={Apidata} />}
                  {<MostOb Apidata={Apidata} />}
                  {<Mostqa Apidata={Apidata} />}
                  {<Averagecalls Apidata={Apidata} />}
                </SimpleGrid>

              </Box>
            }

          </>

        </div> :

          <Box mt='0px' pt={{ base: "10px", md: "20px", xl: "20px" }}  >
            <div style={containerStyle}>
              <div style={messageStyle}>
                <h3 textAlign='center' > Please Select a Team !!</h3>
                {team?.length > 0 &&

                  <Select width={{ base: '100px', xl: '300px', md: '300px' }} bg='grey'
                    borderColor='#DBDDDB'
                    box-shadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    variant='outline' onChange={handleTeamChange} value={selectedTeam}>

                    {selectedTeam == 'Select' && <option value="" >Select Your Team</option>}
                    {team.map((team, index) => (
                      <option key={index} value={team}>{team.toUpperCase()}</option>
                    ))}

                  </Select>}

              </div>
            </div>

          </Box>



        }


      </Box>




    )
}


export default Sortby;