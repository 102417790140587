import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";





const Testinggraph = () => {

  const textColor = useColorModeValue("secondaryGray.900", "white");

  var chartss = JSON.parse(localStorage.getItem('user'));

  const [datachart, setDatachart] = useState(chartss);




  const chartOptions = {

    yaxis: {
      show: false
    }
    ,

    chart: {
      toolbar: {
        show: true,
        tools:{
          download:false ,
         // <== line to add
        }
      }
    },

    grid: {

      borderColor: 'transparent',
      strokeDashArray: 1,
    },

    fill: {
      colors: ['#439A3C', '#FF7F50', '#26CA26']
    },


    // ... your existing option
    options: {
      chart: {
        height: 720,
        type: 'bar',
      }
    },

    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 2,
          colorFrom: '#FF7F50',
          colorTo: '#FF7F50',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.2,
        }
      }
    }

    ,
    title: {
      text: '',
      floating: true,
      align: 'center',
      style: {
        color: '#444'
      }
    },
    xaxis: {
      tickPlacement: 'on',
      categories: datachart !== 'null' && datachart.output
      .filter(item => item.allstats_date !== 'null' && item.allstats_date !== undefined)
      .map(item => item.allstats_date),
      position: 'bottom',
      axisBorder: {
        show: true
      },
      axisTicks: {
        
        show: true
      },
      labels: {
        show: true,
        rotate: -90,
        rotateAlways: false,
        minHeight: 20,
        maxHeight: 70,
        style: {
         
        }
      },
    },

    plotOptions: {
      
      bar: {
        borderRadius: 5,
        dataLabels: {
          
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      
      enabled: true,
      dropShadow: {
        enabled: true,
        left: 2,
        top: 2,
        opacity: 0.1
    },
      formatter: function (value) {
        return value;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },




  };


  return (


    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
         <Flex px='25px' justify='space-between' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>

         Total Calls 
        </Text>
      </Flex>
      
      <Box px='20px' h='450px' mt='10px' >


        { datachart.output !== null ?
          <ReactApexChart options={chartOptions} series={[{ name: 'Number of Calls', data: datachart.output
          .map(item => item.allstats_calls)
          .filter(allstats_calls => allstats_calls !== 'null' && allstats_calls !== undefined) }]} type="bar" height='100%' width='100%' />
          : ''
        }


      </Box>


    </Card>
  )
}

export default Testinggraph;