
import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/today/components/DevelopmentTable";
import CheckTable from "./components/CheckTable";


import React from "react";
import Userdailyreport from './components/Userdailyreport'
import Eachcalls from "./components/Eachcall";
import { Helmet } from "react-helmet";


export default function Settings() {

  const data = JSON.parse(localStorage.getItem('user'));

  
  const containerStyle = {
    borderRadius: '10px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    background: '#aa4b6b;', /* fallback for old browsers */
    background: '-webkit-linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b);',  /* Chrome 10-25, Safari 5.1-6 */
    background: 'linear-gradient(to right, #3b8d99, #6b6b83, #aa4b6b)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    // Add any other styles as needed
  };

  const messageStyle = {
    color: '#fff', // Set the text color
    fontSize: '30px', // Adjust the font size as needed
    textAlign: 'center', // Center the text
  };


  // Chakra Color Mode
  return (


    <Box pt={{ base: "40px", md: "0px", xl: "0px" }}>

<Helmet>
        <title> Today calls - Advisors stats</title>
      </Helmet>

      {data.output[0].Total_calls_today == '0' ?
        <Box mt='0px' pt={{ base: "0px", md: "80px", xl: "80px" }}  >
          <div style={containerStyle}>
            <div style={messageStyle}>
              <h1 textAlign='center' > You have no Calls today</h1>
            </div>
          </div>

        </Box>
        :

        <Box>
          <Userdailyreport />
          <Box>  <Eachcalls />
            <SimpleGrid
              mt='20px'
              columns={{ sm: 1, md: 2 }}
              spacing={{ base: "20px", xl: "20px" }}>
              <DevelopmentTable/>
              <CheckTable /> 
            </SimpleGrid>
          </Box>


        </Box>


      }

    </Box>
  );
}
