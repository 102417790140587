import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Text } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import { useEffect, useState } from "react";
import { Link as ChakraLink, Image } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';


export function SidebarBrand() {


  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  const storedWebsiteDetails = JSON.parse(localStorage.getItem('websitedetails'));
  const data = JSON.parse(localStorage.getItem('user'));
  const [formattedTime, setFormattedTime] = useState('');
  const [timesinceLastCall, setTimesinceLastCall] = useState('');
  const [timeDifference, setTimeDifference] = useState('');



  useEffect(() => {
    // Assuming data.output[0].timesincelastcall is a string representation of time
    // Convert it to a Date object
    const timesinceLastCallDate = new Date(data.output[0].timesincelastcall);

    // Check if the parsed date is valid
    if (!isNaN(timesinceLastCallDate)) {
      setTimesinceLastCall(timesinceLastCallDate);
    } else {
      console.error("Invalid date string:", data.output[0].timesincelastcall);
    }
  }, [data.output]);

  useEffect(() => {
    const getCurrentFormattedDateTime = () => {
      const now = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true, // Use 12-hour clock with AM/PM
        timeZone: 'America/Chicago', // Adjust time zone as needed
      };
      const formattedDateTime = now.toLocaleString('en-US', options).replace(',', '');
      setFormattedTime(formattedDateTime);
    };

    const intervalId = setInterval(getCurrentFormattedDateTime, 1000); // Update every second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    // Assuming data.output[0].timesincelastcall is a string representation of time
    // Convert it to a Date object
    const timesinceLastCallDate = (data.output[0].timesincelastcall);
    setTimesinceLastCall(timesinceLastCallDate);
  }, [data.output]);

  useEffect(() => {
    const date1 = new Date(formattedTime);
    const date2 = new Date(timesinceLastCall);

    const timeDiffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());

    const hours = Math.floor(timeDiffInMilliseconds / (60 * 60 * 1000));
    const minutes = Math.floor((timeDiffInMilliseconds % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((timeDiffInMilliseconds % (60 * 1000)) / 1000);

    const formattedDifference = `${hours}h ${minutes}m ${seconds}s`;

    setTimeDifference(formattedDifference);
  }, [formattedTime, timesinceLastCall]);


  return (
    <Flex height='auto' align='center' direction='column' mr='10px' paddingTop='10px' >
      <ChakraLink as={ReactRouterLink} to="/admin/default">
        {
          storedWebsiteDetails[0]?.dash_logo ?
            <img style={{ width: '200px', borderRadius: '10px' }} src={storedWebsiteDetails[0]?.dash_logo} alt="Trulli" width="200" height="133"></img> :
            <Text fontSize='20px' fontWeight='700' > {storedWebsiteDetails[0]?.name} </Text>
        }
      </ChakraLink>

      {timeDifference &&
        <div style={{ display: 'inline-block', padding: '5px' }}>
          <Text style={{ fontSize: '15px', margin: 'auto' }}>
            Last Call <Text style={{ fontWeight: 'bold', color: 'purple', display: 'inline' }}>{timeDifference}</Text> ago
          </Text>
        </div> 
      }  
          <HSeparator marg margin='10px' />
    </Flex>
  );
}

export default SidebarBrand;
