import React, { useState, useEffect } from 'react';

function TeamTotalOB() {
    // State to store unique teams and total OB month for each team
    const [uniqueTeams, setUniqueTeams] = useState(new Set());
    const [teamTotalOB, setTeamTotalOB] = useState({});

    useEffect(() => {
        // Retrieve data from localStorage
        const data = JSON.parse(localStorage.getItem('leaderboard')) || [];

        // Calculate unique teams and total OB month for each team
        const calculateTeamTotalOB = () => {
            const teamOB = {};

            data.forEach(item => {
                const team = item.team;
                const obMonth = parseInt(item.obmonth) || 0; // Default to 0 if obmonth is undefined/null

                // Add team to uniqueTeams set
                setUniqueTeams(prevTeams => new Set([...prevTeams, team]));

                // Update total OB month for the team
                teamOB[team] = (teamOB[team] || 0) + obMonth;
            });

            // Update teamTotalOB state
            setTeamTotalOB(teamOB);
        };

        calculateTeamTotalOB();
    }, []);

    return (
        <div>
           
            <h2>Total OB Month for Each Team:</h2>
            <ul>
                {Object.entries(teamTotalOB).map(([team, totalOB]) => (
                    <li key={team}>{team}: {totalOB}</li>
                ))}
            </ul>
        </div>
    );
}

export default TeamTotalOB;
