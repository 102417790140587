// Chakra imports
import { Link, Button, Avatar, Box, Flex, Text, useColorModeValue, SimpleGrid } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { ExternalLinkIcon, EditIcon, UnlockIcon } from '@chakra-ui/icons'


export default function Banner2(props) {

  const userdetails = JSON.parse(localStorage.getItem('userdetails'));

    const data = JSON.parse(localStorage.getItem('user'));

  const { banner } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    <Card p='10px' mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='231px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={userdetails.dp}
        name={userdetails.name}
        h='217px'
        w='217px'
        mt='-93px'
        border='4px solid'
        borderColor={borderColor}
      />


      <Text color={textColorPrimary} fontWeight='bold' fontSize='30px' mt='10px'>

        {userdetails.name}

      </Text>
      <Text mb='20px' color={textColorSecondary} fontSize='m'>
        {userdetails.designation}
      </Text>

      <Flex margin='auto'   >
        <Flex mx='auto' align='center' direction='column'>
        </Flex>
        <SimpleGrid p='10px' margin='auto' columns={{ base: 1, md: 2, lg:2, "2xl":2 }} gap='20px' >
        <Button size='sm' colorScheme='green'>
            <Link
              _hover={{ bg: 'none', color: 'inherit' }} // Override hover behavior
              _focus={{ bg: 'none', color: 'inherit' }} // Override focus behavior
              href='https://docs.google.com/spreadsheets/d/1iVncKSMWMsW90lQiZHClkdszAq8es6LsOZZCPYHPYvg/htmlview' isExternal ml={2}>
              OOH sheet <EditIcon mx='2px' />
            </Link>
          </Button>

          <Button size='sm' colorScheme='green'>
            <Link
              _hover={{ bg: 'none', color: 'inherit' }} // Override hover behavior
              _focus={{ bg: 'none', color: 'inherit' }} // Override focus behavior
              href={userdetails.sheetlink} isExternal ml={2}>
              Update Call sheet <EditIcon mx='2px' />
            </Link>
          </Button>

          <Button size='sm' colorScheme='green'>
            <Link
              _hover={{ bg: 'none', color: 'inherit' }} // Override hover behavior
              _focus={{ bg: 'none', color: 'inherit' }} // Override focus behavior
              href={userdetails.sheetlink} isExternal ml={2}>
              Date Range of Report <ExternalLinkIcon mx='2px' />
            </Link>
          </Button>

          <Button size='sm' colorScheme='green'>
            <Link
              _hover={{ bg: 'none', color: 'inherit' }} // Override hover behavior
              _focus={{ bg: 'none', color: 'inherit' }} // Override focus behavior
              href={userdetails.sheetlink} isExternal ml={2}>
              Change Password <UnlockIcon mx='2px' />
            </Link>
          </Button>
        </SimpleGrid>

      </Flex>
      <Text>
      Api Status : {data.output[0]?.api_status}
      </Text>
    </Card>
  );
}
