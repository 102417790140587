import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";





const Testinggraph = () => {

  const textColor = useColorModeValue("secondaryGray.900", "white");

  var chartss = JSON.parse(localStorage.getItem('user'));

  const [datachart, setDatachart] = useState(chartss);





  // Extract the data for x-axis (date) and y-axis (duration in seconds)
  const xData = datachart !== 'null' && datachart.output
  .filter(item => item.month_date !== 'null' && item.month_date !== undefined)
  .map(item => item.month_date)
  

  const yData = datachart.output
  .map(item => item.month_duration)
  .filter(duration => duration !== 'null' && duration !== undefined)
  .map(duration => {
    const [hours, minutes, seconds] = duration.split(':');
    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
  });


  // Use the processed data in the series
  const chartOptions = {


    chart: {
      
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    },
      toolbar: {
        show: true,
        tools:{
          download:false ,
         // <== line to add
        }
      }
    },

    grid: {
      
      
      borderColor: 'transparent',
      strokeDashArray: 1,
    },

    fill: {
      colors: ['#6E8A0F', '#FF7F50', '#26CA26']
    },

   
 xaxis: {
  tickPlacement: 'on',
  
  categories: xData,
  position: 'bottom',
  axisBorder: {
    show: true
  },
  axisTicks: {
    show: true
  },
  labels: {
    show: true,
    rotate: -90,
    rotateAlways: true,
    minHeight: 20,
    maxHeight: 70,
    style: {
     
    }
  },
}   ,
    yaxis: {
      show: false, // Hide entire y-axis
      labels: {
        formatter: function (val) {
          const hours = Math.floor(val / 3600);
          const minutes = Math.floor((val % 3600) / 60);
          const seconds = val % 60;
          return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        const hours = Math.floor(val / 3600);
        const minutes = Math.floor((val % 3600) / 60);
        const seconds = val % 60;
        return `${hours.toString().padStart(2, '')}h${minutes.toString().padStart(2, '0')}m`;
      },
      dropShadow: {
        
        enabled: true,
        left: 0.5,
        top: 0.5,
        opacity: 0.1
    },
    offsetY: -25,

    style: {
      fontSize: '12px',
      colors: ["#304758"],

    }
    },

    plotOptions: {
    
      bar: {
        borderRadius: 4,

        dataLabels: {
          position: 'top',
        }
      }
    },
  };




  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      mt='20px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>

          Call hours
        </Text>
      </Flex>
      <Box px='20px' h='450px' mt='10px' >


        {datachart.output !== null ?


          <ReactApexChart
            options={chartOptions}
            series={[{ name: 'Total Hours on call', data: yData }]}
            type="bar"
            height={450}
          />

          : ''
        }


      </Box>


    </Card>
  )
}

export default Testinggraph;
