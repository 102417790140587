

import React, { useState , useEffect  } from "react";
import {
  Box,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets

// Custom components

import CheckTable from "./components/callhours";
import ComplexTable from "./components/calltype";
import PieCard from "./components/PieCard";
import WeeklyRevenue from "./components/totalcalls";
import Noti from '../../../components/Notifications/Noti'
import Userdetails from "./components/userdetails";
import Allthreemonth from "../Anaylsis/Components/allthreemonth"
import ObBas from "../Anaylsis/Components/ObBas"
import Threemonthhour from "../Anaylsis/Components/Threemonthworkinghour"
import { Helmet } from "react-helmet";



export default function UserReports() {

  useEffect(() => {
    // Google Tag Manager script
    (function(w,d,s,l,i) {
      w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NDLRRXBH');
  }, []);

  

  const [hide, sethide] = useState(false);
  const websitedetails = JSON.parse(localStorage.getItem('websitedetails'));

  var chartss = JSON.parse(localStorage.getItem('user'));
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const closeModal = () => {
    setModalIsOpen(false);
  };
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");



  return (
    <Box mt='0px' pt={{ base: "40px", md: "80px", xl: "80px" }}

    >

<Helmet>
        <title> Main Dashboard - Advisors stats</title>
      </Helmet>
      

      {websitedetails[0]?.notificationmsg && <Noti isOpen={modalIsOpen} onClose={closeModal} />}

     { chartss?.output[0]?.lm_allcalls !== 0 && 
     
     <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='20px' mb='20px' >
       <Allthreemonth/>
       <ObBas/>
       <Threemonthhour/>
      
      </SimpleGrid> }
      
      <Userdetails/>

 

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <WeeklyRevenue />
      </SimpleGrid>


      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px' templateColumns={{ xl: '60% 40%' }}>
        <ComplexTable

        />
        <PieCard />
      </SimpleGrid>


      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>

        <CheckTable />


      </SimpleGrid>
    </Box>
  );
}











