// Login.js

import React, { useEffect, useState } from 'react';
import { websitedetails_api } from '../../Api'
import { userdetails_api } from '../../Api';
import './Signin.css'
import toast from 'react-hot-toast';
import axios from 'axios';
import { ThreeDots } from 'react-loading-icons'
import { Box } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';







const Sign = (props) => {

  // const userData = JSON.parse(localStorage.getItem('user'));
  // const [data, setData] = useState(userData);
  // const ApiLinkkkk = localStorage.getItem('Api')
  // const [Apilink,setApilink] = useState(ApiLinkkkk)


  const storedWebsiteDetails = JSON.parse(localStorage.getItem('websitedetails'));

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const userApi = process.env.REACT_APP_USER_DETAILS_ENDPOINT
  const [loading, setLoading] = useState(false);
  const [websitedetails, setwebsitedetails] = useState(storedWebsiteDetails);
  const currentdate = new Date()?.toISOString();
	const Apileaderboard = process.env.REACT_APP_LEADERBOARD_ENDPOINT



  useEffect(() => {
    //for website details
    const fetchData = async () => {
      try {
        
        // Fetch data from the API
        // const response = await fetch(websitedetailsapi);
        // const data = await response.json();

        const data = await websitedetails_api();

     // Save data to local storage with name 'websitedetails'
        localStorage.setItem('websitedetails', JSON.stringify(data));
        setwebsitedetails(data)
        console.log('websitedetails')

        // Update state with the fetched data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts


  //check user and login
  const handleLogin = async () => {

    try {
      setLoading(true);
      toast.loading('hold on 😉 ')


      // Replace 'apiEndpoint' with the actual API endpoint
      const response = await axios.get(userApi);
      const result = await response.data;

  


      // Check if the entered username and password match any entry in the API response
      const user = result.output.find((entry) => {
        // Case-insensitive comparison for username
        const isUsernameMatch = entry.advisor.toLowerCase() === username.toLowerCase();

        // Case-sensitive comparison for password (you can modify this based on your requirements)
        const isPasswordMatch = entry.password.toString() === password.toString();

        return isUsernameMatch && isPasswordMatch;
      });
      if (user) {
        toast.dismiss();
        toast.success('Logging in!');

        const Getuserdata = result.output.find(item => item.advisor === username);

        // Check if the advisor "king" exists in the data
        if (Getuserdata) {
          localStorage.setItem("userdetails", JSON.stringify(Getuserdata));
          localStorage.setItem('token', 'gsyuifkjdgvekrsvuyjdtgfye26735634y2q581');

          // Access the Api link of the "king" advisor
          const Apilink = Getuserdata["Api"]
          localStorage.setItem("Api", Apilink);
          const usertype = Getuserdata["usertype"]
          localStorage.setItem("usertype", usertype);

          const Loadapi = async () => {

            toast.loading('Loading your stats 😉 ')

            try {
              const response = await fetch(Apilink);
              const result = await response.json();

              const filteredData = result.output.filter(item => Object.keys(item).length > 0);
              const filteredResult = { "output": filteredData };

              localStorage.setItem('user', JSON.stringify(filteredResult));
              localStorage.setItem('logindate', currentdate);
              // window.location.reload(false);
              toast.dismiss();
              window.location.reload(false);

            } catch (error) {
              console.error('Error fetching data:', error);
            }

          }

          
            const fetchleaderboard = async () => {
              try {
                const response = await fetch(Apileaderboard);
                const result = await response.json();
                localStorage.setItem('leaderboard', JSON.stringify(result));  
                } catch (error) {
                console.error('Error fetching data:', error);
                }
            };
            fetchleaderboard();





          Loadapi();

        }


        // Successful login, handle accordingly (e.g., set authentication state)

      } else {
        toast.dismiss();
        setLoading(false);
        // Invalid credentials
        toast.error('invalid credentials, Please connect with King');
      }
    } catch (error) {
      toast.dismiss();
      setLoading(false);
      toast.error('invalid credentials,Please connect King 🙏 ');
    }
  };

  //checking if they have enter username and password then calling Api
  const HandleLoginn = async () => {
    if (username && password) {
      handleLogin()
    } else {
      toast.error("Please enter Credentials!")
    }
  }




  return (
    <div >
      <div>
        <div className="login-container">
          <div className='maindiv' >
            <div className='form1' >
              <div className="login-form1">

                <img className="login-image" src={websitedetails && websitedetails[0]?.login_logo} />

                <label className='label' htmlFor="username">Username</label>
                <input
                  className='inputusername'
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />

                <label className='label' htmlFor="password">Password</label>
                <input
                  className='inputusername'
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {error && <p className="error-message">{error}</p>}

                <button className='signinbutton' onClick={HandleLoginn} disabled={loading}>
                  {loading ? <ThreeDots className='Loginloading' />
                    : 'LOGIN'

                  }
                </button>

              </div>
            </div>

            <Box display={{ base: 'none', sm: 'none', md: 'none', lg: 'yes', '2xl': 'yes' }}     
            >

              <div className='loginform2' >
                <Swiper

                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>        <img width="100%" src="https://i.postimg.cc/6QKZDpMr/Frame-1-7.png" />
                  </SwiperSlide>

                  <SwiperSlide>        <img width="100%" src="https://i.postimg.cc/zX9KFjWP/Frame-3.png" />
                  </SwiperSlide>
                  <SwiperSlide>        <img width="100%" src="https://i.postimg.cc/WbD0ZKDk/Frame-2.png" />
                  </SwiperSlide>
                  <SwiperSlide>        <img width="100%" src="https://i.postimg.cc/HkX5Zy9W/Frame-4.png" />
                  </SwiperSlide>



                </Swiper>

              </div>

            </Box>
            <div>

            </div>
          </div>
        </div>

        <div className='fooooter'>
          <Footer />
        </div>
      </div>

    </div>
  );
};

export default Sign;
