import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Avatar,
  Tr,
  Box,
  useColorModeValue,
  TableCaption,
  TableContainer, Tfoot
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import '../../Leaderboard.css'

// Custom components
import Card from "components/card/Card";
import { SlReload } from "react-icons/sl";
import { PiArrowElbowDownLeftBold } from "react-icons/pi";







export default function Hours(props) {


  const userdetails = JSON.parse(localStorage.getItem('userdetails'));


  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card
      className='leaderboardbox'
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>

      <Flex width='100%' className="RankAlltime" height='40px'>
        <Text
          margin='auto'
          color={textColor}
          fontSize='lg'
          fontWeight='700'
          lineHeight='100%'>
          Average Call per Day
        </Text>


      </Flex>
      {props.Apidata == undefined ? <Box height='110px'> <SlReload className="loading-iconframe" /> </Box> :
        <TableContainer overflowX="hidden">
          <Table size='sm' variant='simple'>
            <Thead>
              <Tr>
                <Th color='gray.400'>Rank</Th>
                <Th color='gray.400'>Name</Th>
                <Th color='gray.400'>Calls</Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.Apidata
                .slice() // Create a copy to avoid modifying the original array
                .sort((a, b) => parseFloat(b.Averagealltime) - parseFloat(a.Averagealltime)) // Sort in ascending order
                .map((row, index) => (
                  <Tr className={row.name == userdetails?.name ? 'Rank' : ''} key={index}>
                    <Td >{index + 1 }  </Td>
                    <Td>
                      <Text style={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                          bgGradient="linear(to-tr, teal.300, blue.500)"
                          borderRadius="full"
                          p="2px"  // Add padding to prevent gradient clipping
                          display="inline-block"
                          mr='10px'
                        >
                          <Avatar
                            color="white"
                            bg='linear(to-tr, teal.300, blue.500)'
                            src={row?.dp}
                            name={row?.name}
                            fontFamily="monospace"
                            size="sm"
                            w="25px"
                            h="25px"
                          />
                        </Box>                               {row.name}
                        {row.name == userdetails?.name && <PiArrowElbowDownLeftBold style={{ fontWeight:'800' , fontSize:'18px' , marginLeft:'10px' ,  color:' #654caf' }} /> }   
                      </Text>
                    </Td>
                    <Td>{row.Averagealltime} </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>

      }



    </Card>
  );
}