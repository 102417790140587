
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/userprofile/components/Banner";
import General from "views/admin/userprofile/components/General";

// Assets
import Userprofilee from "assets/img/auth/userprofilee.png";

import React from "react";
import { Helmet } from "react-helmet";


export default function userprofile() {


  return (
    <Box pt={{ base: "50px", md: "80px", xl: "60px" }}>
      <Helmet>
        <title> Advisor Profile - Advisors stats</title>
      </Helmet>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr ",
        }}
        templateRows={{
          base: "repeat(1fr, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={Userprofilee}
        />

     
        <General/>
      
       
      </Grid>
    </Box>
  );
}
