// Chakra Imports

import {
	Avatar,
	Button,
	Flex,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text, useColorModeValue
} from '@chakra-ui/react';
import { FiRefreshCw } from "react-icons/fi";
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { IoSettings } from "react-icons/io5";
import { MdPalette } from 'react-icons/md'

// Custom Components
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import routes from 'routes.js';
import { ThemeEditor } from './ThemeEditor';
import toast from 'react-hot-toast';
import Signmain from 'views/auth/signIn/index';
import Loginas from './Loginas/Loginas';
import { IconButton } from '@chakra-ui/react';
import { LuArrowUpRightFromCircle } from "react-icons/lu";
import { Tooltip } from '@chakra-ui/react';



export default function HeaderLinks(props) {
	const history = useHistory();
	const Apileaderboard = process.env.REACT_APP_LEADERBOARD_ENDPOINT




	const { secondary } = props;

	// Chakra Color Mode
	const brandColor = useColorModeValue("brand.500", "white");
	let mainText = useColorModeValue('navy.700', 'white');
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
	const usertype = localStorage.getItem('usertype')

	const userdetails = JSON.parse(localStorage.getItem('userdetails'));
	var ApiLink = localStorage.getItem("Api");
	const [reload, setreload] = useState(false);
	const location = useLocation();
	const [Slug, Setslug] = useState('')


	useEffect(() => {
		// Extract the slug from the URL
		const parts = location.pathname.split('/');
		const lastPart = parts[parts.length - 1];

		Setslug(lastPart)

	}, [location.pathname]);


	const logout = () => {
		toast.loading('Logging out!');

		setTimeout(() => {
			redirectToRoot()
			for (let key in localStorage) {
				if (key !== 'websitedetails') {
					localStorage.removeItem(key);
				}
			}
			toast.dismiss();
			toast.success('Logged out Successfully')
		}, 1000);
	}

	const redirectToRoot = () => {
		<Switch>
			<Route path={``} component={Signmain} />
			<Redirect to='/' />
		</Switch>

		window.location.reload(false);
	};

	const fetchData = async () => {
		try {
			const startTime = Date.now(); // Record start time
			setreload(true);
			toast.loading("Refreshing");

			let response;
			if (Slug !== "leaderboard") {
				response = await fetch(ApiLink);
			} else {
				response = await fetch(Apileaderboard);
			}

			const result = await response.json();

			localStorage.setItem(Slug !== "leaderboard" ? 'user' : 'leaderboard', JSON.stringify(result));
			console.log(Slug !== "leaderboard" ? 'main api' : 'leaderboard');

			const endTime = Date.now(); // Record end time
			const duration = ((endTime - startTime) / 1000).toFixed(1); // Calculate duration in seconds with one decimal place
			toast.dismiss();
			toast.success(`Refreshed in ${duration}s`); // Show duration on toast
			history.replace(`/admin/${Slug}`); // Use newSlug as part of the route

		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setreload(false);
		}
	};

	return (
		<Flex
			display='flex'
			gridColumn='5'
			columns={{ sm: 5, md: 4 }}
			margin='5px'
			w={{ sm: '60%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			borderRadius="15px"
			boxShadow={shadow}
			gap='10px'
			p='5px'
			px='10px'
		>




			<Text
				width='50%'
				ml='10px'
				display={{ sm: 'yes', lg: 'none', md: 'none', '2xl': 'none' }}

				color={mainText}
				bg='inherit'
				borderRadius='inherit'
				fontFamily={'kanit'}
				fontSize='15px'
				fontWeight='black'
				_hover={{ color: { mainText } }}
				_active={{
					bg: 'inherit',
					transform: 'none',
					borderColor: 'transparent'
				}}
				_focus={{
					boxShadow: 'none'
				}}>
				{Slug == 'default' && 'Main Dashboard'}
				{Slug == 'today-stats' && 'Today Dashboard'}
				{Slug == 'this-month' && 'This Month Dashboard'}
				{Slug == 'userprofile' && 'User Profile'}
				{Slug == 'developerprofile' && 'Developer'}
				{Slug == 'leaderboard' && 'Leaderboard'}
				{Slug == 'report' && 'Report'}
				{Slug == 'last-month' && 'Last Month Report'}
				{Slug == 'analysis' && 'Analysis'}

			</Text>

			<Flex >
				<SidebarResponsive routes={routes} />
			</Flex>

			{/* 
			<ThemeEditor w="40px"
				h="40px" display={{ base: 'none', sm: 'none', lg: 'yes', md: 'yes', '2xl': 'yes' }}
				navbarIcon={navbarIcon} /> */}

			<Tooltip borderRadius={5} label="OOH sheet" fontSize="md">
				<Link
					h="40px"
					display={{ base: 'none', sm: 'none', lg: 'yes', md: 'yes', '2xl': 'yes' }}
					_hover={{ bg: 'none', color: 'inherit' }} // Override hover behavior
					_focus={{ bg: 'none', color: 'inherit' }} // Override focus behavior
					href='https://docs.google.com/spreadsheets/d/1iVncKSMWMsW90lQiZHClkdszAq8es6LsOZZCPYHPYvg/htmlview'
					isExternal
				>
					<IconButton
						colorScheme='blue'
						aria-label='OOH sheet'
						icon={<LuArrowUpRightFromCircle />}
					/>
				</Link>
			</Tooltip>


			{reload ? <Button padding='0'  > <FiRefreshCw className='loading-icon2' /> </Button> :


				<Tooltip borderRadius={5} label="Refresh Data" fontSize="md">
					<IconButton
						p='0'
						color='white'
						onClick={fetchData}
						icon={<FiRefreshCw />}
						style={{
							fontWeight: '1000',
							background: 'linear-gradient(270deg, #1817ff, #ff0000, #f900ff, #13024d)',
							backgroundSize: '800% 800%',
							WebkitAnimation: 'AnimationName 4s ease infinite',
							MozAnimation: 'AnimationName 4s ease infinite',
							animation: 'AnimationName 4s ease infinite'
						}}
						aria-label='Refresh Data'
					/>
				</Tooltip>

			}

			<Menu>
				<MenuButton w='45px' h='45px' bg='linear-gradient(240deg, #f39053, #8858db);' borderRadius='50%'    >
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color="white"
						src={userdetails.dp}
						name={userdetails.name}
						bg='linear-gradient(240deg, #f39053, #8858db);'
						fontFamily='serif'
						size="sm"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" borderRadius="20px" bg={menuBg} border="none">
					<Flex w="100%" mb="-10px">
						<Text
							ps="20px"
							pt="16px"
							pb="10px"
							w="100%"
							borderBottom="1px solid"
							borderColor={borderColor}
							fontSize="sm"
							fontWeight="700"
							color={textColor}>
							👋&nbsp; Hey,  {userdetails.name}
						</Text>
					</Flex>
					<Flex flexDirection="column" p="10px">
						<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} >

							<Button rightIcon={<IoSettings />} colorScheme='purple' style={{ width: '100%', fontSize: 'sm' }}>
								<Link
									_hover={{ bg: 'none', color: 'inherit' }} // Override hover behavior
									_focus={{ bg: 'none', color: 'inherit' }} // Override focus behavior
									href="#/admin/userprofile"
								>
									Profile Settings
								</Link>

							</Button>
						</MenuItem>
						<MenuItem display={{ base: 'yes', sm: 'yes', lg: 'none', md: 'none', '2xl': 'none' }}  >

							<Button rightIcon={<MdPalette />} colorScheme='purple' style={{ fontSize: 'sm' }}>
								Theme editor
								<ThemeEditor display={{ base: 'yes', sm: 'yes', lg: 'none', md: 'none', '2xl': 'none' }}
								/>
							</Button>

						</MenuItem >

						{usertype === "admin" && <Loginas />}


						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color="red.400"
							borderRadius="8px"
							px="14px">
							<Button _hover={{ bg: 'red.600' }} bg='red.200' width='100%' onClick={logout} fontSize="sm"> Log out </Button>


						</MenuItem>

					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
