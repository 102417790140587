// Chakra imports
import { Link, Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaSpotify } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaCoffee } from "react-icons/fa";
import Buyme from "components/Buyme/Buymecoffe";



export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    <Card mb={{ base: "20px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='231px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src='https://i.postimg.cc/3xjG3X8z/409418794-318721610992323-1607615161142480240-n.jpg'
        h='217px'
        w='217px'
        mt='-93px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='30px' mt='10px'>
        King raj
      </Text>
      <Text color={textColorSecondary} fontSize='m'>
        unfortunately, Customer Success Advisor
      </Text>
      <Flex margin='auto' mt='20px' mb='15px' gap='25px' >

        <Flex mx='auto' align='center' direction='column' >
          <a href="https://www.instagram.com/kabirrr07/" target="_blank" rel="noopener noreferrer">
            <FaInstagram size='30px' color="#C13584" />
          </a>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <a href="https://open.spotify.com/user/31tkkea3cbzutkbvrkrem7z22z2e?si=c82ec19cbd194af3" target="_blank" rel="noopener noreferrer">
            <FaSpotify
              size='30px' color="#1db954" />
          </a>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <a href="https://www.linkedin.com/in/king-raj-246776198/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size='30px' color="#0077b5" />
          </a>
        </Flex>
       </Flex>

       <Flex mx='auto' align='center' direction='row'>
          <a target="_blank" rel="noopener noreferrer">
          <Text color={textColorSecondary} fontSize='m'>
         <Buyme  />

        </Text>
         
          </a>
        </Flex>
    </Card>
  );
}
