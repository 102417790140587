import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";

const Testinggraph = () => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const chartData = JSON.parse(localStorage.getItem('user'));
  const [datachart, setDatachart] = useState(chartData);

  const chartOptions = {
    series: [
      Math.max(datachart.output[0].oballtime, 0.0), // Minimum value set to 0.1
      Math.max(datachart.output[0].qaalltime, 0.0), // Minimum value set to 0.1
      Math.max(datachart.output[0].basalltime, 0.0), // Minimum value set to 0.1
      Math.max(datachart.output[0].allstats_tickets, 0.0) // Minimum value set to 0.1
    ],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['OB', 'Q&A', 'BAS', 'Tickets'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 500
          },
          legend: {
            show: false,
          }
        }
      }]
    },
  };

  return (
    <Card
      direction='column'
      w='100%'
      h='100%'
      margin='auto'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>

      <Box h='auto'>
        {datachart.output !== null ?
          <ReactApexChart
            options={chartOptions.options}
            series={chartOptions.series}
            type="pie"
            height={240}
          />
          : ''
        }
      </Box>
    </Card>
  );
};

export default Testinggraph;
