import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";





const Testinggraph = () => {

  const textColor = useColorModeValue("secondaryGray.900", "white");

  var chartss = JSON.parse(localStorage.getItem('user'));

  const [datachart, setDatachart] = useState(chartss);



 const  chartOptions = {


  
    series: [{
      name: '',
      data:  [ datachart.output[0].oballtime , datachart.output[0].qaalltime ,datachart.output[0].basalltime , datachart.output[0].allstats_tickets ]
    }],
    options: {
      
      
      chart: {
        toolbar: {
          show: true,
          tools:{
            download:false ,
           // <== line to add
          }
        },
        height: '',
        type: 'bar',
        events: {
          click: function(chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      
      grid: {
        borderColor: 'transparent',
        strokeDashArray: 1,
      },
      colors: [ '#DA0FC7', '#0DA428', '#5A61EE', '#135790'],
      
      plotOptions: {
        bar: {
          borderRadius: 2,
          columnWidth: '45%',
          distributed: true,
          dataLabels: {
            enabled: true,
            position:'top',
          },
        },
      
      },
      legend: {
        show: true
      },

      dataLabels: {
        enabled: true,
      
        dropShadow: {
          
          enabled: true,
          left: 1,
          top: 1,
          opacity: 0.1
      },
      offsetY: -20,
  
      style: {
        fontSize: '12px',
        colors: ["#304758"],
  
      }
      },
      xaxis: {
        categories: [ 'OB', 'Q&A', 'BAS', 'Tickets'
        ],
        labels: {
          style: {
            colors:[ '#DA0FC7', '#12BB31', '#5A61EE', '#135790'],
            fontSize: '12px'
          }
        }
      },
      yaxis:{
        show:false
      }
    },
  }
  





return (
//callType

  <Card
    direction='column'
    w='100%'
    h='100%'
   margin='auto'
    px='0px'
    overflowX={{ sm: "scroll", lg: "hidden" }}>
   
    <Box  h='auto' >


      {datachart.output !== null ?


        <ReactApexChart
        margin='auto'
          options={chartOptions.options}
          series={chartOptions.series}
          type="bar" height={240}
        />

        : ''
      }


    </Box>


  </Card>
)
    }

export default Testinggraph;
