/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports

import { FcMusic } from "react-icons/fc";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import React, {  useState } from "react";
import {
  Text,
  Link,
  Box,
  Icon,

  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import { TbLoader } from "react-icons/tb";

// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  
  MdOutlineQueryBuilder,
  MdAvTimer,
  MdBarChart,
 

} from "react-icons/md";



export default function UserReports() {
  const data = JSON.parse(localStorage.getItem('user'));
  const [hide, sethide] = useState(false);

  const userdetails = JSON.parse(localStorage.getItem('userdetails'));
  let Apidata = JSON.parse(localStorage.getItem('leaderboard'));

  if (!Apidata) {
    // Handle the case where 'leaderboard' data is not found in localStorage
    // For example, you might want to set Apidata to an empty array:
    Apidata = [];
  }

  const totalcallsranktoday = Apidata
  .slice() // Create a copy to avoid modifying the original array
  .sort((a, b) => b.totalcallstoday - a.totalcallstoday) // Sort in descending order
  .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name


  const calltimerank = Apidata
    .slice() // Create a copy to avoid modifying the original array
    .sort((a, b) => {
        // Convert time strings to total seconds
        const timeA = timeToSeconds(a.todaycalltime);
        const timeB = timeToSeconds(b.todaycalltime);
        // Sort in descending order
        return timeB - timeA;
    })
    .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

// Function to convert time string to total seconds
function timeToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}




  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box mt='0px' pt={{ base: "0px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 4 }}
        px={{lg:'50px'}}
        gap='15px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <div>


               <div className="ranktransparento"  >
                  <Text>
                    <div style={{ display: "inline-block" }}>
                      <span>#</span>
                      {totalcallsranktoday !== -1 && <h1 style={{ display: "inline" }}> {totalcallsranktoday + 1}</h1>}
                    </div>
                  </Text>

                </div>
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />

                </div>
                
               
              }
            />
          }
          name='Today All Calls '
          value={

            <div className="stats">

                {data.output[0].Total_calls_today == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].Total_calls_today}  </Text>
                }

            </div>
          }

        />




        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={

                <div>
                   <div className="ranktransparento"  >
                  <Text>
                    <div style={{ display: "inline-block" }}>
                      <span>#</span>
                      {calltimerank !== -1 && <h1 style={{ display: "inline" }}>{ calltimerank + 1}</h1>}
                    </div>
                  </Text>

                </div>
                <Icon w='32px' h='32px' as={MdOutlineQueryBuilder} color={brandColor} />
                </div>
              }
            />
          }
          name='Today Call Hours'
          value={

            <Box className="stats">


              <Box>

                {data.output[0].Total_calls_today == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '0px' }}> {data.output[0].Total_call_Time_Today} <span style={{fontSize:'10px' , color:'grey'}}> out of </span>  <span style={{ marginLeft:'5px' , fontSize:'16px', color:'#95A5A6' }} >   {data.output[0].totalworkinghourstoday} </span>&nbsp;<span style={{fontSize:'10px'}}>{data.output[0].todaycalltimepercentage} </span>  </Text>
                }


              </Box>

            </Box>

          }

        />
        <MiniStatistics

          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAvTimer} color={brandColor} />
              }
            />
          }

          name='Average Time per Call ' value=
          {

            <div className="stats">

{data.output[0].Average_calls_Today == null ? <TbLoader
  className="loading-icon" />

  : <Text style={{ marginLeft: '5px' }}> {data.output[0].Average_calls_Today}  </Text>
}



            </div>


          } />




        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='24px' h='24px' as={FcMusic} color='white' />}
            />
          }
          name={<Text style={{ display: 'flex', alignItems: 'center' , marginBottom:'0px' }}>
          Hours without Call 
          <Link onClick={() => sethide(prevState => !prevState)} style={{ marginLeft: '10px' }}>
            { hide ? <BiShow fontSize='20px' /> : <BiHide  fontSize='20px' /> }
          </Link>
        </Text>
         }
          value={<div className="stats">

          {data.output[0].Free_time == null ? <TbLoader
            className="loading-icon" /> : 

            <div>   {hide  ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].Free_time} <span style={{ fontSize: '10px', color: 'grey' }}> out of </span>  <span style={{ marginLeft: '5px', fontSize: '15px', color: '#95A5A6' }} >   {data.output[0].totalworkinghourstoday} </span></Text>

            : <Text style={{ marginLeft: '5px' }}  > ******** </Text>}</div>
          }
        </div>}
        />

      </SimpleGrid>


    </Box>
  );
}














