// Chakra imports
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState,useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
import Signmain from 'views/auth/signIn/index';
import toast from 'react-hot-toast';


// Custom Chakra theme
export default function Dashboard(props) {
	const storedWebsiteDetails = JSON.parse(localStorage.getItem('websitedetails'));
	const Api = localStorage.getItem('Api')
	const userData = JSON.parse(localStorage.getItem('user'));
	const [websitedetails, setwebsitedetails] = useState(storedWebsiteDetails);
	const loginDate = localStorage.getItem('logindate');

	const Apileaderboard = process.env.REACT_APP_LEADERBOARD_ENDPOINT
	const websitedetailsapi = process.env.REACT_APP_API_ENDPOINT


		if (loginDate) {
		  const currentDate = new Date(); // Get current date
		  const storedDate = new Date(loginDate);
	
		  const diffInMilliseconds = currentDate - storedDate;

	
		  if (diffInMilliseconds > 1296000000 ) {
			toast.remove('Login Timeout')
			for (let key in localStorage) {
				if (key !== 'websitedetails') {
					localStorage.removeItem(key);
				}
			}
		    window.location.reload();
			redirectToRoot();
		
		  }
		}
	
	  const redirectToRoot = () => {
		
		<Switch>
			<Route path={``} component={Signmain} />
			<Redirect to='/' />
		</Switch>

		};

		//Google analytics

		useEffect(() => {
			// Google Tag Manager script
			(function(w,d,s,l,i) {
			  w[l]=w[l]||[];
			  w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
			  var f=d.getElementsByTagName(s)[0],
			  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
			  j.async=true;
			  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
			  f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','GTM-NDLRRXBH');
		  }, []);

		  

	// Fetching user data.
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(Api);
				const result = await response.json();
				const filteredData = result.output.filter(item => Object.keys(item).length > 0);
				const filteredResult = { "output": filteredData };
				localStorage.setItem('user', JSON.stringify(filteredResult));  
				} catch (error) {
				console.error('Error fetching data:', error);
				}
		};
		fetchData();
	  },);

	  //callingleaderboard api

  useEffect(() => {
		const fetchleaderboard = async () => {
			try {
				const response = await fetch(Apileaderboard);
				const result = await response.json();
				localStorage.setItem('leaderboard', JSON.stringify(result));  
				} catch (error) {
				console.error('Error fetching data:', error);
				}
		};
		fetchleaderboard();
	  },[]);

	  useEffect(() => {
		//for website details
		const fetchData = async () => {
		  try {
			// Fetch data from the API
			const response = await fetch(websitedetailsapi);
			const data = await response.json();
			// Save data to local storage with name 'websitedetails'
			localStorage.setItem('websitedetails', JSON.stringify(data));
			setwebsitedetails(data)
			console.log('websitedetails')
	
			// Update state with the fetched data
		  } catch (error) {
			console.error('Error fetching data:', error);
		  }
		};
	  fetchData();
	  },[]); 
	  
  

	const { ...rest } = props;
	// states and functions
	const [ fixed ] = useState(false);
	const [ toggleSidebar, setToggleSidebar ] = useState(false);
	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].items);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].items);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].secondary;
				}
			}
		}
		return activeNavbar;
	};
	const getActiveNavbarText = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].messageNavbar;
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === '/admin') {
				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			}
			if (prop.collapse) {
				return getRoutes(prop.items);
			}
			if (prop.category) {
				return getRoutes(prop.items);
			} else {
				return null;
			}
		});
	};
	document.documentElement.dir = 'ltr';
	const { onOpen } = useDisclosure();
	document.documentElement.dir = 'ltr';
	return (
		<Box>
			
			<Box >
				<SidebarContext.Provider
					value={{
						toggleSidebar,
						setToggleSidebar
					}}>
					<Sidebar routes={routes} display='none' {...rest} />
					<Box
						float='right'
						minHeight='100vh'
						height='100%'
						overflow='auto'
						position='relative'
						maxHeight='100%'
						w={{ base: '100%', xl: 'calc( 100% - 230px )' }}
						maxWidth={{ base: '100%', xl: 'calc( 100% - 230px )' }}
						transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
						transitionDuration='.2s, .2s, .35s'
						transitionProperty='top, bottom, width'
						transitionTimingFunction='linear, linear, ease'>
						<Portal>
							<Box>
								<Navbar
									onOpen={onOpen}
									logoText={'GHL Call stats'}
									brandText={getActiveRoute(routes)}
									secondary={getActiveNavbar(routes)}
									message={getActiveNavbarText(routes)}
									fixed={fixed}
									{...rest}
								/>
							</Box>
						</Portal>

						{getRoute() ? (
							<Box mx='auto' p={{ base: '8px', md: '30px' }} pt={{ base: '50px', md: '30px' }} pe='8px' minH='100vh'>
								<Switch>
									{getRoutes(routes)}
									<Redirect from='/' to='/admin/default' />
								</Switch>
							</Box>
						) : null}
						<Box>
							<Footer />
						</Box>
					</Box>
				</SidebarContext.Provider>
			</Box>
		</Box>
	);
}
