

import React, { useEffect, useState, Fragment, useRef } from "react";
import { BiSupport } from "react-icons/bi";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { PiClockFill } from "react-icons/pi";
import { FcMusic } from "react-icons/fc";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import { FcMissedCall } from "react-icons/fc";
import { BsClockHistory } from "react-icons/bs";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,

} from '@chakra-ui/react'
import {
  StatHelpText,
  StatArrow,
} from '@chakra-ui/react'

import {
  Link,
  Text,
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import { TbLoader } from "react-icons/tb";
import { WiDayCloudy } from "react-icons/wi";


// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdOutlineQueryBuilder,
  MdAvTimer,
  MdBarChart
} from "react-icons/md";

import { Circle } from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';




export default function UserReports() {

  const [hide, sethide] = useState(false);


  const data = JSON.parse(localStorage.getItem('user'));

  const userdetails = JSON.parse(localStorage.getItem('userdetails'));
  let Apidata = JSON.parse(localStorage.getItem('leaderboard'));

  if (!Apidata) {
    // Handle the case where 'leaderboard' data is not found in localStorage
    // For example, you might want to set Apidata to an empty array:
    Apidata = [];
  }

  const totalcallsrank = Apidata
    .slice() // Create a copy to avoid modifying the original array
    .sort((a, b) => b.totalcallsmonth - a.totalcallsmonth) // Sort in descending order
    .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name
    
  const averagecallrank = Apidata
    .slice() // Create a copy to avoid modifying the original array
    .sort((a, b) => b.Averagemonthly - a.Averagemonthly) // Sort in descending order
    .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

  const totalobrank = Apidata
    .slice() // Create a copy to avoid modifying the original array
    .sort((a, b) => b.obmonth - a.obmonth) // Sort in descending order
    .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

  const qarank = Apidata
    .slice() // Create a copy to avoid modifying the original array
    .sort((a, b) => b.qamonth - a.qamonth) // Sort in descending order
    .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

  const calltimerank = Apidata
    .slice() // Create a copy to avoid modifying the original array
    .sort((a, b) => parseFloat(b.percentagemonthly) - parseFloat(a.percentagemonthly))  // Sort in descending order
    .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name



  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const mobReport = parseFloat(data.output[0]?.mob_monthly) || 0;
  const reportob = parseFloat(data.output[0]?.obmonth) || 0;

  const TotaloB = (mobReport === 0 || reportob === 0)
    ? (mobReport !== 0 ? mobReport : reportob)
    : mobReport + reportob;


  // stats

  const { output } = data;

  //Total calls
  
  const currentMonthCalls = output[0].Total_calls_Monthly;
  const lastMonthCalls = output[0].lm_allcalls;

  const percentageChange = ((currentMonthCalls - lastMonthCalls) / lastMonthCalls) * 100;
  const type = currentMonthCalls < lastMonthCalls ? 'decrease' : 'increase';




  //total working hours

  const currentMonthhour = parseFloat(output[0].monthlycalltimepercentage);
  const lastMonthCallshour = parseFloat(output[0].lm_callperc);

  const currentMonthValue = currentMonthhour / 100;
  const lastMonthValue = lastMonthCallshour / 100;

  const difference = currentMonthValue - lastMonthValue;
  const percentageChangehour = difference * 100;
  const typeHR = difference < 0 ? 'decrease' : 'increase';


  // average call per day


  const currentMonthaverage = output[0].Average_calls_Monthly;
  const lastMonthaverage = output[0].lm_averagecalls;

  const differenceaverage = currentMonthaverage - lastMonthaverage;
  const percentageaveragehour = (differenceaverage / lastMonthaverage) * 100;
  const typeAverage = currentMonthaverage < lastMonthaverage ? 'decrease' : 'increase';


  //Total OB

  const currentOb = output[0].obmonth;
  const lastMonthOB = output[0].lm_totalob;

  const differenceOB = currentOb - lastMonthOB;
  const percentageOB = (differenceOB / lastMonthOB) * 100;
  const typeOB = currentOb < lastMonthOB ? 'decrease' : 'increase';

  //Total QA

  const currentqa = output[0].qamonth;
  const lastMonthqa = output[0].lm_totalqa;

  const differenceqa = currentqa - lastMonthqa;
  const percentageqa = (differenceqa / lastMonthqa) * 100;
  const typeqa = currentqa < lastMonthqa ? 'decrease' : 'increase';


  //total BAS

  const currentbas = output[0].basmonth;
  const lastMonthbas = output[0].lm_bas;

  const differencebas = currentbas - lastMonthbas;
  const percentagebas = (differencebas / lastMonthbas) * 100;
  const typebas = currentbas < lastMonthbas ? 'decrease' : 'increase';







  return (
    <Box mt='0px' pt={{ base: "40px", md: "80px", xl: "80px" }}>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}

        gap='15px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<div>
                <div style={{ position: 'absolute', right: '2%', bottom: '6%' }}>
                  <Popover >
                    <PopoverTrigger>
                      <Circle _hover={{ cursor: 'pointer' }} // Change cursor to pointer on hover
                        size="15px" bg="blue.500" color="white" display="flex" alignItems="center" justifyContent="center">
                        <FaInfoCircle size={20} />
                      </Circle>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody fontSize='12px'>
                        Rank is being calculated from leaderboard.
                        <br />
                        Stats percentage is being campared from Last month.

                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </div>


                <div className="ranktransparento"  >
                  <Text>
                    <div style={{ display: "inline-block" }}>
                      <span>#</span>
                      {totalcallsrank !== -1 && <h1 style={{ display: "inline" }}>{totalcallsrank + 1}</h1>}
                    </div>
                  </Text>

                </div>
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              </div>
              }
            />
          }
          name='Total Calls'
          value={
            <div className="stats">

              {data.output[0].Total_calls_Monthly == null ? <TbLoader
                className="loading-icon" /> : ''
              }
              {data.output[0].Total_calls_Monthly && (
                <div>

                  {data !== 0 ? <Text style={{ marginLeft: '5px' }}> {data.output[0].Total_calls_Monthly}  </Text>

                    : ''}

                  {data.output[0].lm_allcalls !== '0' &&

                    <StatHelpText letterSpacing={'0.2px'} fontWeight='100' marginLeft='5px' fontSize='12px' >
                      <StatArrow width='12px' type={type} />
                      {Math.abs(percentageChange).toFixed(2)}%
                    </StatHelpText>

                  }
                </div>
              )}


            </div>
          }

        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <div>
                  <div className="ranktransparento"  >
                    <Text>
                      <div style={{ display: "inline-block" }}>
                        <span>#</span>
                        {calltimerank !== -1 && <h1 style={{ display: "inline" }}>{calltimerank + 1}</h1>}
                      </div>
                    </Text>

                  </div>

                  <Icon w='32px' h='32px' as={MdOutlineQueryBuilder} color={brandColor} />
                </div>
              }
            />
          }
          name='Total Call Hours '
          value={<div className="stats">
            {data.output[0].Total_call_time_Monthly == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}> {data.output[0].Total_call_time_Monthly} <span style={{ fontSize: '12px', color: 'grey' }}> out of </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].monthlyworkinghourstotal} </span>&nbsp;<span style={{ fontSize: '10px' }}>{data.output[0].monthlycalltimepercentage} </span> </Text>

                  : ''}

                {data.output[0].lm_allcalls !== '0' &&
                  <StatHelpText letterSpacing={'0.2px'} fontWeight='100' marginLeft='5px' fontSize='12px' >
                    <StatArrow width='12px' type={typeHR} />
                    {Math.abs(percentageChangehour).toFixed(2)}%

                  </StatHelpText>}
              </div>
            )}
          </div>}

        />
        <MiniStatistics

          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <div>
                  <div className="ranktransparento"  >
                    <Text>
                      <div style={{ display: "inline-block" }}>
                        <span>#</span>
                        {averagecallrank !== -1 && <h1 style={{ display: "inline" }}>{averagecallrank + 1}</h1>}
                      </div>
                    </Text>

                  </div>

                  <Icon w='32px' h='32px' as={MdAvTimer} color={brandColor} />
                </div>
              }
            />
          }

          name='Avg. Call per day ' value=
          {<div className="stats">
            {data.output[0].Average_calls_Monthly == null ? <TbLoader
              className="loading-icon" /> : ''}
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}> {data.output[0].Average_calls_Monthly}</Text>

                  : ''}

               {  data.output[0].lm_allcalls !== '0' &&
 <StatHelpText letterSpacing={'0.2px'} fontWeight='100' marginLeft='5px' fontSize='12px' >
                <StatArrow width='12px' type={typeAverage} />
                {Math.abs(percentageaveragehour).toFixed(2)}%

              </StatHelpText>}
              </div>
            )}
          </div>} />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='24px' h='24px' as={PiClockFill} color={brandColor} />}
            />
          }
          name='Average call Duration'
          value={<div className="stats">

            {data.output[0].Average_calls_Time_Monhtly == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].Average_calls_Time_Monhtly}</Text>

                  : ''}

                {/* You can add more <h1> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(to right, #f2709c, #ff9472);'
              icon={<Icon w='38px' h='38px' as={WiDayCloudy
              } color='white' />}
            />
          }
          name='Total working days'
          value={<div className="stats">

            {data.output[0].total_working_day_in_a_month == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].total_working_day_in_a_month}</Text>

                  : ''}

                {/* You can add more <h1> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FcMusic
                } color={brandColor} />
              }
            />
          }
          name={

            <Text style={{ marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
              Hours without Call
              <Link onClick={() => sethide(prevState => !prevState)} style={{ marginLeft: '10px' }}>
                {hide ? <BiShow fontSize='20px' /> : <BiHide fontSize='20px' />}
              </Link>
            </Text>
          }
          value={<div className="stats">

            {data.output[0].Monthly_free_time == null && <TbLoader
              className="loading-icon" />
            }
            {data && (
              <div>
                {hide ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].Monthly_free_time} <span style={{ fontSize: '10px', color: 'grey' }}> out of </span>  <span style={{ marginLeft: '5px', fontSize: '15px', color: '#95A5A6' }} >   {data.output[0].monthlyworkinghourstotal} </span></Text>

                  : <Text style={{ marginLeft: '5px' }}  > ******** </Text>}
                {/* You can add more <Text> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />

        <MiniStatistics
          startContent={
            <div>
              <div className="ranktransparento"  >
                <Text>
                  <div style={{ display: "inline-block" }}>
                    <span>#</span>
                    {totalobrank !== -1 && <h1 style={{ display: "inline" }}>{totalobrank + 1}</h1>}
                  </div>
                </Text>

              </div>

              <IconBox
                w='56px'
                h='56px'
                bg={boxBg} icon={<Icon w='24px' h='24px' as={TbDeviceDesktopAnalytics} color={brandColor} />}
              />
            </div>
          }
          name='Total OB'
          value={
            <div className="stats">


              <div>

                {data.output[0].Total_calls_Monthly == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].obmonth}  <span style={{ fontSize: '12px', color: 'grey' }}> average time </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].atobmonthly}    <span style={{ fontSize: 'sm', color: 'grey' }}> / {data.output[0].OBpercmonthly} </span>    </span> </Text>
                }
              {  data.output[0].lm_allcalls !== '0' &&
  <StatHelpText letterSpacing={'0.2px'} fontWeight='100' marginLeft='5px' fontSize='12px' >
                  <StatArrow width='12px' type={typeOB} />
                  {Math.abs(percentageOB).toFixed(2)}%

                </StatHelpText>}


              </div>

            </div>


          }
        />
        <MiniStatistics
          startContent={
            <div>
              <div className="ranktransparento"  >
                <Text>
                  <div style={{ display: "inline-block" }}>
                    <span>#</span>
                    {qarank !== -1 && <h1 style={{ display: "inline" }}>{qarank + 1}</h1>}
                  </div>
                </Text>

              </div>

              <IconBox
                w='56px'
                h='56px'
                bg={boxBg} icon={<Icon w='24px' h='24px' as={BiSupport} color={brandColor} />}
              />
            </div>
          }
          name='Total Q&A'
          value={
            <div className="stats">


              <div>

                {data.output[0].obmonth == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].qamonth}  <span style={{ fontSize: '12px', color: 'grey' }}> average time </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].atqamonthly}   <span style={{ fontSize: 'sm', color: 'grey' }}> / {data.output[0].QApecMonthly} </span> </span>   </Text>
                }

             {  data.output[0].lm_allcalls !== '0' &&
 <StatHelpText letterSpacing={'0.2px'} fontWeight='100' marginLeft='5px' fontSize='12px' >
                <StatArrow width='12px' type={typeqa} />
                {Math.abs(percentageqa).toFixed(2)}%

              </StatHelpText>}

                {/* qacurrent */}

              </div>

            </div>


          }
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={<Icon w='24px' h='24px' as={FiSettings} color={brandColor} />}
            />
          }
          name='Total BAS'
          value={
            <div className="stats">


              <div>

                {data.output[0].basmonth == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].basmonth}   <span style={{ fontSize: '12px', color: 'grey' }}> average time </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].atbasmonthly}   <span style={{ fontSize: 'sm', color: 'grey' }}> / {data.output[0].BASpercmonhtly} </span> </span> </Text>
                }

{data.output[0].lm_bas !== '0' &&

<StatHelpText letterSpacing={'0.2px'} fontWeight='100' marginLeft='5px' fontSize='12px' >
  <StatArrow width='12px' type={typebas} />
  {Math.abs(percentagebas).toFixed(2)}%
</StatHelpText>

}



              </div>

            </div>


          }
        />





      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap='20px'
        mb='20px'
        marginX={{ base: 0, md: '10%', lg: '10%', "2xl": '10%' }}
      >

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='36px' h='36px' as={BsClockHistory} color={brandColor} />}
            />
          }
          name='Average Working hours'
          value={<div className="stats">

            {data.output[0].at_monthly == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].at_monthly}</Text>

                  : ''}

              </div>
            )}
          </div>}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={<Icon w='28px' h='28px' as={FcMissedCall} color={brandColor} />}
            />
          }
          name='Missed Onboarding This Month'
          value={
            <div className="stats">


              <div>

                {data.output[0]?.mob_monthly == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0]?.mob_monthly}   <span style={{ fontSize: '12px', color: 'grey' }}> out of </span>
                    <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {TotaloB}  <span style={{ fontSize: 'sm', color: 'grey' }}>/ {data.output[0]?.mob_monthlyperc} </span> </span> </Text>
                }


              </div>

            </div>


          }
        />

      </SimpleGrid>



    </Box>
  );
}











