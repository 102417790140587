import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Avatar,
    useColorModeValue,
    TableCaption,
    TableContainer, Tfoot, Box
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";

import '../../Leaderboard.css'
import { SlReload } from "react-icons/sl";


// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { PiArrowElbowDownLeftBold } from "react-icons/pi";
import confetti from "canvas-confetti";



export default function Todaymostcalls(props) {

    const userdetails = JSON.parse(localStorage.getItem('userdetails'));
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");


    const test = () => {
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });
       }
      
    return (
        <Card

            className='leaderboardbox'
            direction='column'
            w='100%'
            px='0px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>

            <Flex width='100%' className="Ranktoday" height='40px'>
                <Text
                    margin='auto'
                    color={textColor}
                    fontSize='20px'
                    fontWeight='700'
                    lineHeight='100%'>
                    HIGHEST CALL HOURS TODAY
                </Text>

            </Flex>

            {props?.Apidata == undefined ? <Box height='110px'> <SlReload className="loading-iconframe" /> </Box> :

                <TableContainer overflowX="hidden">
                    <Table size='sm' variant='simple'>
                        <Thead>
                            <Tr>
                                <Th color='gray.400'>Rank</Th>
                                <Th color='gray.400'>Name</Th>
                                <Th color='gray.400'>Call TIME</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {props.Apidata
                                .slice() // Create a copy to avoid modifying the original array
                                .sort((a, b) => parseFloat(b.percentagetoday) - parseFloat(a.percentagetoday))// Sort in ascending order
                                .map((row, index) => (
                                    <Tr className={row.name == userdetails?.name && 'Rank'} key={index}>
                                        <Td >{index + 1}</Td>

                                        <Td>
                                            <Text style={{ display: 'flex', alignItems: 'center' }}>
                                            <Box
                          bgGradient="linear(to-tr, teal.300, blue.500)"
                          borderRadius="full"
                          p="2px"  // Add padding to prevent gradient clipping
                          display="inline-block"
                          mr='10px'
                        >
                          <Avatar
                            color="white"
                            bg='linear(to-tr, teal.300, blue.500)'
                            src={row?.dp}
                            name={row?.name}
                            fontFamily="monospace"
                            size="sm"
                            w="25px"
                            h="25px"
                          />
                        </Box>                                                {/* //confetti celebration for rank 1 */}
                                                {/* {row.name === userdetails?.name && index === 0 && test()} */}
                                                {row.name}
                                                {row.name == userdetails?.name && <PiArrowElbowDownLeftBold style={{ fontWeight:'800' , fontSize:'18px' , marginLeft:'10px' ,  color:' #654caf' }} /> }   

                                            </Text>
                                        </Td>     
                                        <Td>{row.todaycalltime} <Text className="tablesecondarytext" > {row.percentagetoday} </Text>  </Td>
                                        <Td ></Td>

                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>



            }


        </Card>
    );
}