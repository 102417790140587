// Chakra imports
import { SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";

// Assets
export default function GeneralInformation(props) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
       About me
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
      "With 3 years in Customer Success, excelling as a Customer Success Executive in the SAAS industry. I've effectively managed high-value enterprise and mid-market accounts, handling onboarding, account management, retention, support, and engagement. Serving as a bridge between departments, I translate client needs into actionable solutions. Experienced in B2B SAAS client management globally (US, UK, Australia, Canada), my versatile approach contributes holistically to organizational success."
      </Text>
      <SimpleGrid columns='2' gap='20px'>
        <Information
          boxShadow={cardShadow}
          title='Education'
          value='Shri Ramswaroop memorial university'
        />
        <Information
          boxShadow={cardShadow}
          title='Languages'
          value='English,Hindi'
        />
        <Information
          boxShadow={cardShadow}
          title='Department'
          value='Customer Success'
        />
        <Information
          boxShadow={cardShadow}
          title='Work History'
          value='Winuall , Highlevel'
        />
        <Information
          boxShadow={cardShadow}
          title='Total experience'
          value='3 Years'
        />
        <Information
          boxShadow={cardShadow}
          title='Birthday'
          value='22 March 1999'
        />
      </SimpleGrid>
    </Card>
  );
}
