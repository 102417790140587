import React, { useEffect, useState } from "react";
import {
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Table,
  Tr,
  Td,
  Tbody,
  Box,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import toast from "react-hot-toast";
import './Loginas.css'

export default function Loginas() {
  const Apiforlistofusers = process.env.REACT_APP_USER_DETAILS_ENDPOINT
  let LoginasUsers = JSON.parse(localStorage.getItem('loginAsdata'));

  const [users, setUsers] = useState(LoginasUsers);
  const [searchQuery, setSearchQuery] = useState("");
  const userdetails = JSON.parse(localStorage.getItem("userdetails"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Apiforlistofusers);
        const data = await response.json();
        setUsers(data.output);
        localStorage.setItem('loginAsdata', JSON.stringify(data.output));

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  },[Apiforlistofusers] );

  
  const handleLogin = async (Api, advisor) => {
    try {
      if (users) {
        toast.loading("Logging in");
        const Getuserdata = users.find((item) => item.advisor === advisor);
        if (Getuserdata) {
          localStorage.setItem("userdetails", JSON.stringify(Getuserdata));
          const Apilink = Getuserdata["Api"];
          localStorage.setItem("Api", Apilink);

          const Loadapi = async () => {
            try {
              const response = await fetch(Api);
              toast.success("Login Success");
              const result = await response.json();

              const filteredData = result.output.filter(
                (item) => Object.keys(item).length > 0
              );
              const filteredResult = { output: filteredData };

              localStorage.setItem("user", JSON.stringify(filteredResult));
              window.location.reload(false);
            } catch (error) {
              console.error("Error fetching data:", error);
            }
          };
          Loadapi();
        }
      } else {
        // Handle error
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users?.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Flex flexDirection="column" px="14px">

      <Menu>
        {users == null ? (
          <MenuButton
            width="100%"
            colorScheme="purple"
            fontSize="sm"
            as={Button}
            rightIcon={<Spinner size="sm" />}
            isDisabled
          >
            Checking users
          </MenuButton>
        ) : (
          <MenuButton
            width="100%"
            colorScheme="purple"
            fontSize="sm"
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            Login as
          </MenuButton>
        )}

        <MenuList ml={10} px={2} >

<div class="group">
  <svg class="iconsearch" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
  <input className="searchinput"
                  placeholder="Search advisors..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                 
                />
</div>
          <MenuItem closeOnSelect={false}
            minH="48px">
            <Table  colorScheme="blackAlpha" variant="simple" width="100%" size="sm">
              <Tbody  >
                {filteredUsers?.map((user) => (
                  <Tr key={user?.id}>
                    {userdetails?.name !== user?.name && (
                      <Td onClick={() => handleLogin(user?.Api, user?.advisor)}>
                        <Box
                          as="span"
                          _hover={{ fontSize: "md", color: "blue", cursor: "pointer"  }}
                        >
                          {user?.name}
                        </Box>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </MenuItem>

        </MenuList>
      </Menu>
      
    </Flex>
  );
}
