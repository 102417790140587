import { Box, SimpleGrid, Button , Text } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";
import CheckTable from "./components/CheckTable";
import ColumnsTable from "./components/ColumnsTable";
import ComplexTable from "./components/ComplexTable";


import React from "react";
import Userdailyreport from './components/Userdailyreport'
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';
import { FaCloudDownloadAlt } from "react-icons/fa";
import Info from "./components/Info";
import { Helmet } from "react-helmet";


export default function LastMonth() {
  const userdetails = JSON.parse(localStorage.getItem('userdetails'));
  const targetRef = useRef();
  const data = JSON.parse(localStorage.getItem('user'));


  const containerStyle = {
    borderRadius: '10px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    background: '#2b5876',  /* fallback for old browsers */
    background: ' -webkit-linear-gradient(to right, #4e4376, #2b5876)',  /* Chrome 10-25, Safari 5.1-6 */
    background: ' linear-gradient(to right, #4e4376, #2b5876)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    // Add any other styles as needed
  };

  const messageStyle = {
    color: '#fff', // Set the text color
    fontSize: '30px', // Adjust the font size as needed
    textAlign: 'center', // Center the text
  };


  // Chakra Color Mode
  return (
    <Box pt={{ base: "40px", md: "4.5rem", xl: "4.5rem" }} >

<Helmet>
        <title> Last Month - Advisors stats</title>
      </Helmet>


      {data.output[0].lm_allcalls == 0 ?
        <Box mt='0px' pt={{ base: "50px", md: "50px", xl: "70px" }}  >
          <div style={containerStyle}>
            <div style={messageStyle}>
              <h1 textAlign='center' > You have no calls Last month !!</h1>
            </div>
          </div>

        </Box> :



        <>

<div style={{ textAlign: 'right', marginBottom: '10px' }}>
  <div style={{ marginBottom: '10px' }}>
    <Button
      rightIcon={<FaCloudDownloadAlt />}
      colorScheme='green'
      onClick={() => generatePDF(targetRef, { filename: 'Lastmonthreport_GHL.pdf' })}
    >
      Download
    </Button>
  </div>
  <Text fontSize='10px' fontWeight='600' > Keep your screen zoom to 70% for better pdf result.</Text>
</div>


          <Box ref={targetRef} >

            <Info/>
            <Userdailyreport />
            <DevelopmentTable />
            <SimpleGrid
              mt='0px'
              columns={{ sm: 1, md: 2 }}
              spacing={{ base: "20px", xl: "20px" }}>

              <CheckTable />
              <ComplexTable />
            </SimpleGrid>
            <ColumnsTable
            />
          </Box>


        </>
      }

    </Box>
  );
}
