import {
  Button,
  Flex,
  Image,
  Avatar,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import Buyme from "components/Buyme/Buymecoffe";
import React from "react";


export default function SidebarDocs() {
  const userdetails = JSON.parse(localStorage.getItem('userdetails'));
  const fullName = userdetails.name;
const firstName = fullName.split(" ")[0];

  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");

  return (
    <Flex

    mb='10px'
    className="sidebarbg"
    mr='15px'
      justify='center'
      direction='column'
      align='center'
     
      borderRadius='10px'
      position='relative'>
      <Flex

      className="sidebarbg"
        border='none'
        borderColor={borderColor}
        borderRadius='20%'
        w='48px'
        h='48px'
        align='center'
        justify='center'
        mx='auto'
        position='absolute'
        left='20%'
        top='-20px'
        transform='translate(-50%, 0%)'>

          <Link href="/#/admin/userprofile">     
          <Avatar
          borderRadius='20%'
						_hover={{ cursor: 'pointer' }}
						color="white"
						src={userdetails.dp}
						name={userdetails.name}
						bg='linear-gradient(240deg, #f39053, #8858db);'
						fontFamily='sans-serif'
						size="sm"
						w="43px"
						h="43px"
					/>
          </Link>

      </Flex>


      <Flex

      width='100%'
        direction='column'
        align='center'
        justify='center'
        pt='10px'>
        <Text
        ml='12%'
          fontSize={{ base: "sm", xl: "14px" }}
          color='white'
          fontWeight='bold'
          lineHeight='100%'
          textAlign='center'
          mb="15px"
        
          
          >
        Hi, &nbsp;{firstName} 👋
        </Text>
        
      </Flex>  
      
      <Buyme   />
    </Flex>
  );
}
