
import {
    Flex,
    Box,
    Link,
    Avatar,

    Text,
    useColorModeValue,
    SimpleGrid,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { SlReload } from "react-icons/sl";

import React, { useState, useRef } from "react";

// Custom components
import Card from "components/card/Card";
export default function () {

    const data = JSON.parse(localStorage.getItem('user'));
    const userdetails = JSON.parse(localStorage.getItem('userdetails'));

    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    return (
        <Card
            margin='auto'
            direction='column'
            w='100%'
            p='10px'
            overflowX={{ sm: "scroll", lg: "hidden" }}>
            <SimpleGrid width='100%' px='20px' margin='auto' columns={{ base: 1, md: 2, lg: 2, xl: 2 }}
                gap='15px'
                m='auto'
            >


                <Box height='100%' width='100%' borderRadius='10px' bg={boxBg} px='10%' py='2%'   >
                    <SimpleGrid  w={{ base: '95%', md: '95%', lg: '95%', "2xl": '95%' }}
                        gap='5px' columns={{ base: 2, md: 2, lg: 2, xl: 2 }} >
                        <Text fontSize={{ base: 10, md: 12, lg: 18, xl: 18 }} fontWeight='700'  > Showing Report from</Text>
                        <Text fontWeight='700' fontSize={{ base: 10, md: 12, lg: 18, xl: 18 }} style={{ display: 'flex', alignItems: 'center' }}>
                            <Text color={brandColor}>{data.output[0].reportfromdate}</Text>
                            <Text > &nbsp; to &nbsp; </Text>
                            <Text color={brandColor} >{data.output[0].reporttodate}</Text>
                        </Text>

                        <Text fontSize={{ base: 10, md: 15, lg: 18, xl: 18 }} fontWeight='700' > Total working day  </Text>
                        <Text fontWeight='700' fontSize={{ base: 10, md: 15, lg: 18, xl: 18 }} style={{ display: 'flex', alignItems: 'center' }} color={brandColor} > {data.output[0].reportworkingdays} Days</Text>
                    </SimpleGrid>
                </Box>


                <Box height='auto' width='100%' borderRadius='10px' m='auto' bg={boxBg} px='10%' py='3%' >
                    <SimpleGrid columns={{ base: 2, md: 2, lg: 2, xl: 2 }} >

                        <Box>
                            <Avatar
                                src={userdetails.dp}
                                h='75px'
                                w='75px'
                                border='4px solid green '

                            />
                        </Box>

                        <Box margin='auto' marginLeft='initial'   >
                            <Text mb='0px' fontSize='20px' fontWeight='700' >  {userdetails.name} </Text>
                            <Link color={brandColor} href={userdetails.sheetlink} isExternal > Change report date range <ExternalLinkIcon mx='2px' />  </Link>

                        </Box>

                    </SimpleGrid>
                </Box>


            </SimpleGrid>


        </Card>
    );
}
