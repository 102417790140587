import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";





const Testinggraph = () => {

  const textColor = useColorModeValue("secondaryGray.900", "white");

  var chartss = JSON.parse(localStorage.getItem('user'));

  const [datachart, setDatachart] = useState(chartss);

  const [months, setMonths] = useState([]);

  useEffect(() => {
    const currentDate = new Date();
    const lastThreeMonths = [];
    
    // Loop through the last three months
    for (let i = 2; i >= 0; i--) {
      const month = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      const monthName = month.toLocaleString('default', { month: 'long' });
      lastThreeMonths.push(monthName);
    }
    
    // Update the state with the names of the last three months
    setMonths(lastThreeMonths);
  }, []); // Empty dependency array ensures useEffect runs only once, similar to componentDidMount

  const options = {
    chart: {
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 5,
        left: 7,
        blur: 5,
        opacity: 0.2
      },
      toolbar: {
        show: false,
        tools:{
          download:false
        }
      },
      scrollable: false // <-- Added to make the chart not scrollable

    },
    forecastDataPoints: {
      count: 0
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    dataLabels: {
      enabled: true,
    },

    colors: ['#3503fc', 'black'],
    xaxis: {
      type: 'category',
      categories: months,
      tickAmount: 7
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#ED57C2'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 100, 100, 100]
      },
    },
    yaxis: {
      style:{
      },
        labels: {
          formatter: function (value) {
            return value.toFixed(0) ; // Format value as percentage without decimals
          }
        }
      },
    grid: {
      show: false
    }
  };

  const series = [{
    name: 'BAS',
    data: [ chartss.output[0]?.pm_bas || 0 , chartss.output[0]?.lm_bas || 0 , chartss.output[0]?.basmonth || 0 ]
  }];

  
  return (


    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
         <Flex px='0px' justify='space-between' align='center'>
        <Text
        margin="auto"
          color={textColor}
          fontSize='10px'
          fontWeight='700'
          lineHeight='100%'>


    BAS
        </Text>
      </Flex>
      
      <Box p='5px' h='165px' mt='10px' >


        { datachart.output !== null ?
        <ReactApexChart options={options} series={series} type="line" height="160px" />
        : ''
        }

        


      </Box>


    </Card>
  )
}

export default Testinggraph;