import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Switch} from 'react-router-dom';
import { ChakraProvider } from "@chakra-ui/react";
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import Signmain from 'views/auth/signIn/index';
import { Toaster } from 'react-hot-toast';



const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    
    <ChakraProvider theme={theme}>
    <React.StrictMode>
    <Toaster
				containerStyle={{
					top: 100,
					left: 10,
					bottom: 20,
					right: 20,
                    zIndex:1000,
                    height: 200
				}}
			/>
              <ThemeEditorProvider>
            <HashRouter>
                <Switch>
               
              
                   <Signmain/>
                </Switch>
            </HashRouter>
        </ThemeEditorProvider>
    </React.StrictMode>
    </ChakraProvider>,

  );



  
