import React, { useState, useEffect } from "react";
import ReactApexChart from 'react-apexcharts';
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";





const Testinggraph = () => {

  const textColor = useColorModeValue("secondaryGray.900", "white");


  const userdetails = JSON.parse(localStorage.getItem('userdetails'));
  let Apidata = JSON.parse(localStorage.getItem('leaderboard'));
  var chartss = JSON.parse(localStorage.getItem('user'));

  const [datachart, setDatachart] = useState(chartss);

  const [months, setMonths] = useState([]);

  useEffect(() => {
    const currentDate = new Date();
    const lastThreeMonths = [];
    
    // Loop through the last three months
    for (let i = 2; i >= 0; i--) {
      const month = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
      const monthName = month.toLocaleString('default', { month: 'long' });
      lastThreeMonths.push(monthName);
    }
    
    // Update the state with the names of the last three months
    setMonths(lastThreeMonths);
  }, []); // Empty dependency array ensures useEffect runs only once, similar to componentDidMount

  const totalobrank = Apidata
  .slice() // Create a copy to avoid modifying the original array
  .sort((a, b) => b.obmonth - a.obmonth) // Sort in descending order
  .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

  const totalobranklastmonth = Apidata
  .slice() // Create a copy to avoid modifying the original array
  .sort((a, b) => b.lm_ob - a.lm_ob) // Sort in descending order
  .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

  const totalobrankpm = Apidata
  .slice() // Create a copy to avoid modifying the original array
  .sort((a, b) => b.pm_OB - a.pm_OB) // Sort in descending order
  .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name




  const options = {
    chart: {
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 5,
        left: 7,
        blur: 5,
        opacity: 0.2
      },
      toolbar: {
        show: false,
        tools:{
          download:false
        }
      },
      scrollable: false ,// <-- Added to make the chart not scrollable

    

    },
    forecastDataPoints: {
      count: 0
    },
    stroke: {
      width: 2,
      curve: 'smooth'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (val % 1 === 0) {
          return "# " + val.toString(); // Add "#" before the whole number
        } else {
          return "# " + val.toFixed(2); // Add "#" before the number with 2 decimal places
        }
      },
    },


    colors: ['#3503fc', '#28B463'],
    xaxis: {
      type: 'category',
      categories: months,
      tickAmount: 8
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#FF9A07'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [20, 100, 100, 100]
      },
    },
    grid: {
      show: false
    },

    yaxis: {

        reversed: true ,
        style:{
        },
          labels: {
            show:false,
            formatter: function (value) {
              return value.toFixed(0) ; // Format value as percentage without decimals
            }
          }
        },
  };

  




  const series = [{
    name: 'OB Rank',
    data: [totalobrank + 1 || 0, totalobranklastmonth + 1 || 0, totalobrankpm + 1 || 0].reverse()
  }];
  
  return (


    <Card
    h='170px'
    border='5px'
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
         <Flex px='0px' justify='space-between' align='center'>
        <Text
        margin="auto"
          color={textColor}
          fontSize='10px'
          fontWeight='700'
          lineHeight='100%'>


   OB RANK 
        </Text>
      </Flex>
      
      <Box p='5px' h='105px' mt='10px' >


        { datachart.output !== null ?
        <ReactApexChart options={options} series={series} type="line" height="105px" />
        : ''
        }

        


      </Box>


    </Card>
  )
}

export default Testinggraph;