/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports

import { FcMusic } from "react-icons/fc";
import { BiHide } from "react-icons/bi";
import { BiShow } from "react-icons/bi";
import React, { useState } from "react";
import { BiSupport } from "react-icons/bi";
import { FcMissedCall } from "react-icons/fc";
import { BsClockHistory } from "react-icons/bs";


import {
  Text,
  Link,
  Box,
  Flex,
  FormLabel,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { WiDayCloudy } from "react-icons/wi";

// Assets
import { TbLoader } from "react-icons/tb";

import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";

// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {
  MdAddTask,
  MdOutlineQueryBuilder,
  MdAvTimer,
  MdBarChart,
} from "react-icons/md";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,

} from '@chakra-ui/react'
import { Circle } from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';


export default function Userdetails() {
  const data = JSON.parse(localStorage.getItem('user'));
  const [hide, sethide] = useState(false);


  const userdetails = JSON.parse(localStorage.getItem('userdetails'));
let Apidata = JSON.parse(localStorage.getItem('leaderboard'));

if (!Apidata) {
  // Handle the case where 'leaderboard' data is not found in localStorage
  // For example, you might want to set Apidata to an empty array:
  Apidata = [];
}

const totalcallsrank = Apidata
  .slice() // Create a copy to avoid modifying the original array
  .sort((a, b) => b.totalcallsalltime - a.totalcallsalltime) // Sort in descending order
  .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

const averagecallrank = Apidata
  .slice() // Create a copy to avoid modifying the original array
  .sort((a, b) => b.Averagealltime - a.Averagealltime) // Sort in descending order
  .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

const totalobrank = Apidata
  .slice() // Create a copy to avoid modifying the original array
  .sort((a, b) => b.oballtime - a.oballtime) // Sort in descending order
  .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

const qarank = Apidata
  .slice() // Create a copy to avoid modifying the original array
  .sort((a, b) => b.qaalltime - a.qaalltime) // Sort in descending order
  .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name

const calltimerank = Apidata
  .slice() // Create a copy to avoid modifying the original array
  .sort((a, b) => parseFloat(b.percentagealltime) - parseFloat(a.percentagealltime))  // Sort in descending order
  .findIndex(row => row.name === userdetails?.name); // Find index where name matches userdetails.name




  const mobReport = parseFloat(data.output[0]?.mob_alltime) || 0;
  const reportob = parseFloat(data.output[0]?.oballtime) || 0;

  const TotaloB = (mobReport === 0 || reportob === 0)
    ? (mobReport !== 0 ? mobReport : reportob)
    : mobReport + reportob;




  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box mt='0px' pt={{ base: "0px", md: "0px", xl: "0px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}

        gap='15px'
        mb='20px'>




        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <div>

                  <div className="ranktransparento"  >
                    <Text>
                      <div style={{ display: "inline-block" }}>
                        <span>#</span>
                        {totalcallsrank !== -1 && <h1 style={{ display: "inline" }}>{totalcallsrank + 1}</h1>}
                      </div>
                    </Text>

                  </div>

                  <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
                </div>
              }
            />
          }
          name='Total Calls'
          value={

            <div className="stats">
              {data.output[0]?.total_calls_until_now == null ? <TbLoader
                className="loading-icon" /> : ''
              }
              {data && (
                <div>
                  {data !== 0 ? <Text style={{ marginLeft: '5px' }}> {data.output[0].total_calls_until_now}

                  </Text>
                    : ''}</div>
              )}</div>
          }

        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <div>
                  <div style={{ position: 'absolute', right: '2%', bottom: '6%' }}>
                      <Popover >
                        <PopoverTrigger>
                          <Circle _hover={{ cursor: 'pointer' }} // Change cursor to pointer on hover
                            size="15px" bg="blue.500" color="white" display="flex" alignItems="center" justifyContent="center">
                            <FaInfoCircle size={20} />
                          </Circle>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverBody fontSize='12px'>
                           Working Hours are being calculated on 7 hour 30 Minutes per day shift.

                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </div>
                  <div className="ranktransparento"  >
                  <Text>
                    <div style={{ display: "inline-block" }}>
                      <span>#</span>
                      { calltimerank !== -1 && <h1 color={brandColor} style={{ display: "inline" }}>{calltimerank + 1}</h1>}
                    </div>
                  </Text>

                </div>
                <Icon w='32px' h='32px' as={MdOutlineQueryBuilder} color={brandColor} />
                </div>
              }
            />
          }
          name='Total Call time'
          value={<div className="stats">
            {data.output[0].Total_call_time_All == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ?
                  <Text style={{ marginLeft: '0px' }}> {data.output[0].Total_call_time_All} <span style={{ fontSize: '10px', color: 'grey' }}> out of </span>  <span style={{ marginLeft: '5px', fontSize: '15px', color: '#95A5A6' }} >   {data.output[0].Totalworkinghours} </span>&nbsp;<span style={{ fontSize: '10px' }}>{data.output[0].Totalcalltimepercentage} </span>
                  </Text>

                  : ''}

                {/* You can add more <Text> elements for other properties as needed */}
              </div>
            )}
          </div>}

        />
        <MiniStatistics

          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<div>
                <div className="ranktransparento"  >
                  <Text>
                    <div style={{ display: "inline-block" }}>
                      <span>#</span>
                      {averagecallrank !== -1 && <h1 color={brandColor} style={{ display: "inline" }}>{averagecallrank + 1}</h1>}
                    </div>
                  </Text>

                </div>

                <Icon w='32px' h='32px' as={MdAvTimer} color={brandColor} />
              </div>
              }
            />
          }

          name='Average Call' value=
          {<div className="stats">
            {data.output[0].Average_calls_All == null ? <TbLoader
              className="loading-icon" /> : ''}
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}> {data.output[0].Average_calls_All}
                </Text>

                  : ''}

                {/* You can add more <Text> elements for other properties as needed */}
              </div>
            )}
          </div>} />


        <MiniStatistics

          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(to right, #f2709c, #ff9472);'
              icon={
                <Icon w='32px' h='32px' as={WiDayCloudy} color='white' />
              }
            />
          }
          endContent={
            <Flex me='-16px' mt='10px' m='30px' >
              <FormLabel htmlFor='balance'>

              </FormLabel>

            </Flex>
          }
          name='Total Working Day'
          value={<div className="stats">

            {data.output[0].Total_working_days_All == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '0' }}> {data.output[0].Total_working_days_All} <span style={{ fontSize: '8px', color: 'grey' }} >days</span> <span style={{ fontSize: '10px', color: 'grey' }}>  Since </span>  &nbsp;<span style={{ fontSize: '12px' }}>{data.output[0].datasince} </span>&nbsp;<span style={{ fontSize: '10px', color: 'grey' }}>{data.output[0].dayssince} Days</span>   </Text>

                  : ''}

                {/* You can add more <Text> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />


        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='24px' h='24px' as={MdAddTask} color='white' />}
            />
          }
          name='Average Call Duration'
          value={<div className="stats">

            {data.output[0].Average_calls_time_All == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].Average_calls_time_All}</Text>

                  : ''}

                {/* You can add more <Text> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FcMusic
                } color={brandColor} />
              }
            />
          }
          name={

            <Text style={{ marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
              Hours without Call
              <Link onClick={() => sethide(prevState => !prevState)} style={{ marginLeft: '10px' }}>
                {hide ? <BiShow fontSize='20px' /> : <BiHide fontSize='20px' />}
              </Link>
            </Text>
          }

          value={<div className="stats">

            {data.output[0].Free_all_time == null && <TbLoader
              className="loading-icon" />


            }
            {data && (
              <div>

                {hide ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].Free_all_time} <span style={{ fontSize: '10px', color: 'grey' }}> out of </span>  <span style={{ marginLeft: '5px', fontSize: '15px', color: '#95A5A6' }} >   {data.output[0].Totalworkinghours} </span></Text>

                  : <Text style={{ marginLeft: '5px' }}  > ******** </Text>}



                {/* You can add more <Text> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={

                <div>
                  <Icon w='24px' h='24px' as={TbDeviceDesktopAnalytics} color={brandColor} />
                  <div className="ranktransparento"  >
                    <Text>
                      <div style={{ display: "inline-block" }}>
                        <span>#</span>
                        {totalobrank !== -1 && <h1 style={{ display: "inline" }}>{totalobrank + 1}</h1>}
                      </div>
                    </Text>

                  </div>

                </div>
              }
            />
          }
          name='Total OB'
          value={
            <div className="stats">


              <div>

                {data.output[0].oballtime == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].oballtime}  <span style={{ fontSize: '12px', color: 'grey' }}> average time </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].oballtimeaverage}    <span style={{ fontSize: 'sm', color: 'grey' }}> / {data.output[0].ObpercAlltime} </span>    </span>

                  </Text>
                }
              </div>
            </div>
          }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={
                <div>
                  
                  <div className="ranktransparento"  >
                    <Text>
                      <div style={{ display: "inline-block" }}>
                        <span>#</span>
                        {qarank !== -1 && <h1 color={brandColor} style={{ display: "inline" }}>{qarank + 1}</h1>}
                      </div>
                    </Text>

                  </div>
                  <Icon w='24px' h='24px' as={BiSupport} color={brandColor} />

                </div>
              }
            />
          }
          name='Total Q&A'
          value={
            <div className="stats">


              <div>

                {data.output[0].qaalltime == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].qaalltime}  <span style={{ fontSize: '12px', color: 'grey' }}> average time </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].qaalltimeaverage}   <span style={{ fontSize: 'sm', color: 'grey' }}> / {data.output[0].QaPercAlltime} </span> </span>   </Text>
                }


              </div>

            </div>


          }
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={<Icon w='24px' h='24px' as={FiSettings} color={brandColor} />}
            />
          }
          name='Total BAS'
          value={
            <div className="stats">


              <div>

                {data.output[0].basalltime == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0].basalltime}   <span style={{ fontSize: '12px', color: 'grey' }}> average time </span>  <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {data.output[0].basalltimeaverage}   <span style={{ fontSize: 'sm', color: 'grey' }}> / {data.output[0].BasPercalltime} </span> </span> </Text>
                }


              </div>

            </div>


          }
        />




      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap='20px'
        mb='20px'
        marginX={{ base: 0, md: '10%', lg: '10%', "2xl": '10%' }}
      >

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={<Icon w='36px' h='36px' as={BsClockHistory} color={brandColor} />}
            />
          }
          name='Average Working hours'
          value={<div className="stats">

            {data.output[0].at_average == null ? <TbLoader
              className="loading-icon" /> : ''


            }
            {data && (
              <div>

                {data.output.length > 0 ? <Text style={{ marginLeft: '5px' }}  > {data.output[0].at_average}</Text>

                  : ''}

                {/* You can add more <Text> elements for other properties as needed */}
              </div>
            )}
          </div>}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg} icon={<Icon w='28px' h='28px' as={FcMissedCall} color={brandColor} />}
            />
          }
          name='Missed Onboarding of All time'
          value={
            <div className="stats">


              <div>

                {data.output[0]?.mob_alltime == null ? <TbLoader
                  className="loading-icon" />

                  : <Text style={{ marginLeft: '5px' }}> {data.output[0]?.mob_alltime}   <span style={{ fontSize: '12px', color: 'grey' }}> out of </span>
                    <span style={{ marginLeft: '5px', fontSize: '16px', color: '#95A5A6' }} >   {TotaloB}  <span style={{ fontSize: 'sm', color: 'grey' }}>/ {data.output[0]?.mob_alltimeperc} </span> </span> </Text>
                }


              </div>

            </div>


          }
        />

      </SimpleGrid>



    </Box>
  );
}














