// Chakra imports
import { Box, SimpleGrid, Button, Text } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";
import CheckTable from "./components/CheckTable";
import ColumnsTable from "./components/ColumnsTable";
import ComplexTable from "./components/ComplexTable";
import Info from "./components/Info";
import React from "react";
import Userdailyreport from './components/Userdailyreport'
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";


export default function Monthlyreport() {

  const targetRef = useRef();


  const data = JSON.parse(localStorage.getItem('user'));
  const containerStyle = {
    borderRadius: '10px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
    background: '#1D2B64',  /* fallback for old browsers */
    background: ' -webkit-linear-gradient(to right, #F8CDDA, #1D2B64)',  /* Chrome 10-25, Safari 5.1-6 */
    background: 'linear-gradient(to right, #F8CDDA, #1D2B64)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    // Add any other styles as needed
  };

  const messageStyle = {
    color: '#fff', // Set the text color
    fontSize: '30px', // Adjust the font size as needed
    textAlign: 'center', // Center the text
  };


  // Chakra Color Mode
  return (
    <Box pt={{ base: "40px", md: "4.5rem", xl: "4.5rem" }} >
      <Helmet>
        <title> Report - Advisors stats</title>
      </Helmet>

   { data.output[0].reportallcalls !== 0 &&   <div style={{ textAlign: 'right', marginBottom: '10px' }}>
        <div style={{ marginBottom: '10px' }}>
          <Button
            rightIcon={<FaCloudDownloadAlt />}
            colorScheme='green'
            onClick={() => generatePDF(targetRef, { filename: 'Report_GHL.pdf' })}
          >
            Download report
          </Button>
        </div>
        <Text fontSize='10px' fontWeight='600' > Keep your screen zoom to 70% for better pdf result. </Text>
      </div> }


      <Box pt={{ base: "10px", md: "10px", xl: "10px" }} ref={targetRef}>

        {data.output[0].reportallcalls == 0 ?
          <Box mt='0px' pt={{ base: "0px", md: "0px", xl: "0px" }}  >
            <div style={containerStyle}>
              <div style={messageStyle}>
                <h1 textAlign='center' > You have no Calls in this Date Range !!</h1>
              </div>
            </div>

          </Box>
          :
          <>
            <Info />
            <Userdailyreport />
            <DevelopmentTable />
            <SimpleGrid
              mt='20px'
              columns={{ sm: 1, md: 2 }}
              spacing={{ base: "20px", xl: "20px" }}>

              <CheckTable />
              <ComplexTable />
            </SimpleGrid>
            <ColumnsTable

            />
          </>
        }

      </Box>
    </Box>
  );
}
